/** @format */

export default {
  data() {
    return {
      contentHeight: 0,
      contentHeightHeader: this.$vuetify.breakpoint.smAndDown ? 144 : 144,
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize, true);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize, true);
  },
  methods: {
    onResize() {
      let height = window.innerHeight;
      this.contentHeight = height - this.contentHeightHeader + (this?.title === true ? 0 : 68) - (this.$vuetify.breakpoint.smAndDown ? 20 : 0);
      this.$root.windowSize.contentHeight = this.contentHeight;
      this.$root.windowSize.innerHeight = height;
      this.$emit('sendHeight', this.contentHeight);
    },
  },
};

<template>
  <div class="py-1 mb-4">
    <v-card :class="{ 'error--text': showError }">
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <div> 
          <v-row style="min-height: 150px; max-height: 350px; overflow: auto" @drop="dropItem" @dragover="(e) => e.preventDefault()">
            <v-card
              v-for="(el, key) in items"
              :key="key"
              height="150px"
              width="150px"
              class="ma-2"
              @dragover="(e) => onDragOver(el, key, e)"
              @dragend="(e) => finishDrag(el, key, e)"
              @dragstart="(e) => startDrag(el, key, e)"
              @dragleave="onDragLeave(el, key, $event)"
              :class="{ over: el === over.item && el !== dragFrom, [over.dir]: el === over.item && el !== dragFrom }"
            >
              <a :href="$root.config.imageUrl + el" target="_blank">
                <v-img :src="$root.config.imageUrl + el + '?width=150&height=150'" />
                <v-btn v-if="!readonly" class="mx-2" fab dark small color="error" style="position: absolute; right: 8px; top: 8px" title="Удалить" @click.prevent="remove(key)">
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </a>
            </v-card>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <div class="v-text-field__details" v-if="showError">
      <div class="v-messages theme--light" role="alert">
        <div class="v-messages__wrapper error--text">{{ err }}</div>
      </div>
    </div>
    <v-dialog v-model="dialogFile" hide-overlay persistent max-width="600">
      <v-card class="">
        <v-card-title>Загрузка изображений</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input multiple outlined chips label="Выберите файл изображений" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">Загрузить</v-btn
          ><v-btn @click="dialogFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    model: Object,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      uploadsFiles: null,
      dialogFile: false,
      fileLoading: false,
      showError: false,
      err: null,
      over: {},
      startLoc: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    items: {
      get() {
        let d;
        try {
          // d = JSON.parse(this.value);
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    dropItem: function (e) {
      if (this.dragFrom == this.model.name) return;
      let el = this.items;
      if (!Array.isArray(el)) el = [];
      //   el.unshift(e.dataTransfer.getData("text"));
      el.splice(this.over?.pos || 0, 0, e.dataTransfer.getData("text"));
      this.items = el;
    },
    startDrag(item, i, e) {
      e.dataTransfer.setData("text", item);
      e.dataTransfer.effectAllowed = "move";
      this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = this.model.name;
    },
    finishDrag(item, pos, e) {
      this.dragFrom = null;
      this.items.splice(pos, 1);
      this.items.splice(this.over.pos, 0, item);
      setTimeout(() => {
        this.over = {};
      }, 50);
    },

    onDragOver(item, pos, e) {
      if (this.over.pos == pos) return;
      const dir = this.startLoc < e.clientY ? "down" : "up";
      this.over = { item, pos, dir };
      //      setTimeout(() => {        this.over = { item, pos, dir };      }, 50);
    },
    onDragLeave(item, pos, e) {
      if (this.over.pos == pos) this.over = {};
    },
    remove(key) {
      let e = this.items;
      e.splice(key, 1);
      this.items = e;
    },
    addImage() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.dialogFile = false;
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        console.log("promises file", file);

        formData.append("file", file);
        formData.append("name", file.name);
        // file.uploadStatus = 0;
        this.$axios
          .post("/image/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: (progressEvent) => {
            //   file.uploadStatus = parseInt(
            //     (progressEvent.loaded / progressEvent.total) * 100
            //   );
            // },
          })
          .then((response) => {
            let data = response.data.data.file;
            let el = this.items;
            if (!Array.isArray(el)) el = [];
            el.push(data);
            this.items = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            // this.$root.$emit("show-info", {
            //   text: "Error: " + error,
            // });
            reject(error);
          });
      });
    },
  },
};
</script>
<style lang="scss">
.over {
  opacity: 0.6;
}
</style>

<template>
  <div class="py-1 pb-3">
    <v-dialog v-model="value" hide-overlay persistent max-width="600">
      <v-card>
        <v-card-title>Загрузка файлов</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input
            multiple
            outlined
            chips
            label="Выберите файл"
            type="file"
            v-model="uploadsFiles"
          ></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="uploadsFiles ? false : true"
            :loading="fileLoading"
            color="primary"
            @click="upload()"
            >Загрузить
          </v-btn>
          <v-btn @click="$emit('input')">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    files: String | Array,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    value: Boolean,
  },
  data() {
    return {
      uploadsFiles: null,
      dialogFile: false,
      fileLoading: false,
    };
  },
  computed: {
    items: {
      get() {
        let d;
        try {
          d = this.files;
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("save", v);
      },
    },
  },
  methods: {
    add() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.$emit("input");
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.$axios
          .post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            let data = response.data.data.file;
            let el = this.items;
            if (!Array.isArray(el)) el = [];
            el.push({ file: data, name: file.name });
            this.items = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          });
      });
    },
  },
};
</script>
<template>
  <v-btn color="primary" @click="onUnblock()" v-if="!!id && !!api && blocked" :loading="loading" class="px-0" icon>
    <v-icon class="mx-0">fas fa-unlock</v-icon>
    <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title">{{ title }}</btn-title>
  </v-btn>
</template>

<script>
export default {
  props: {
    size: { type: String, default: "sm" },
    id: { type: Number, default: 0 },
    blocked: { type: Number, default: 0 },
    api: { type: String, default: "" },
  },
  data() {
    return {
      title: "",
      loading: false,
    };
  },
  methods: {
    async onUnblock() {
      if (!this.api) return;
      try {
        this.loading = true;
        let data = { id: this.id, blocked: 0 };
        console.log("unblock ", this.api, data);
        let response = await this.$axios.post(this.api, data);
        this.loading = false;
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Документ разблокирован",
          });
          this.$emit("unblock");
          return;
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Документ не может быть разблокирован",
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$root.$emit("show-info", {
          type: "error",
          text: `Документ не может быть разблокирован 
          ${error.data.message}`,
        });
        return;
      }
    },
  },
};
</script>

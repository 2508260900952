/** @format */

export default {
  appName: 'crm-mko',
  version: 83,
  baseUrl: 'https://api.ebazar.asia/api',
  siteUrl: 'https://cabinet.ebazar.asia/',

  //baseUrl: 'https://81.163.31.148/api',
  websocetUrl: 'wss://api.ebazar.asia/data',
  videoUrl: 'https://api.ebazar.asia/api/file/download/',
  imageUrl: 'https://api.ebazar.asia/api/image/download/',
  fileUrl: 'https://api.ebazar.asia/api/file/download/',
  //  recordUrl: 'https://tel.gk-mechti.ru/',
  mainMenu: [
    { auth: 10001, name: 'Главная', icon: 'fas fa-border-all', route: { name: 'index' } },
    {
      name: 'Кабинет',
      icon: 'mdi-briefcase-variant-outline',
      accessName: 'subCabinet',
      sublist: [
        { auth: 10 + 1000, name: 'Статистика работ', route: { name: 'workStatistics' } },
        { auth: 10 + 1000, name: 'Мои данные', route: { name: 'myData' } },
      ],
    },
    { auth: +1000, name: 'Поставщики', icon: 'fas fa-user-friends', route: { name: 'vendor' } },
    { auth: +1000, name: 'Магазины поставщиков', icon: 'fas fa-store', route: { name: 'my-shop' } },
    { auth: +1000, name: 'Магазины', icon: 'fas fa-store', route: { name: 'shop' } },
    { auth: +1000, name: 'МКО', icon: 'mdi-bank', route: { name: 'credit-org' } },
    { auth: +1000, name: 'Запросы на кредит', icon: 'fas fa-shipping-fast', route: { name: 'loan-requests' } },
    { auth: +1000, name: 'Поставки', icon: 'fas fa-shipping-fast', route: { name: 'supply' } },
    { auth: +1000, name: 'Платежи', icon: 'fas fa-file-invoice-dollar', route: { name: 'payment' } },
    { auth: +1000, name: 'Экспедиторы', icon: 'fas fa-walking', route: { name: 'deliveryMan' } },
    {
      auth: 1000,
      name: 'Настройки',
      icon: 'fas fa-cog',
      accessName: 'subSettings',
      sublist: [
        { auth: 1000, route: { name: 'dirs' }, name: 'Справочники', icon: 'fas fa-book' },
        { auth: 1000, route: { name: 'users' }, name: 'Пользователи', icon: 'fas fa-user-cog' },
        { auth: 1000, route: { name: 'organizations' }, name: 'Организации', icon: 'fas fa-user-cog' },
        { auth: 1000, route: { name: 'settings' }, name: 'Настройки', icon: 'fas fa-cog' },
        { sys: '', auth: 1000, route: { name: 'editConfig' }, name: ' Конфигуратор', icon: 'fas fa-cog' },
        { sys: '', auth: 1000, route: { name: 'uploadsGroup' }, name: 'Группы доступа', icon: ' mdi-file-cog' },
        { sys: '', auth: 1000, route: { name: 'uploads-admin' }, name: 'Загрузки', icon: ' mdi-file-eye' },
      ],
    },
  ],
  meta: {
    enum: {
      status_active: [
        { value: 1, text: 'Активно' },
        { value: 2, text: 'Не активно' },
      ],
      status_document: [
        { value: 1, text: 'Не проведен' },
        { value: 2, text: 'Проведен' },
      ],
      yesNo: [
        { value: 0, text: 'Нет' },
        { value: 1, text: 'Да' },
      ],
    },
  },
};

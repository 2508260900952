<template>
  <div class="pr-2">
    <v-progress-circular v-show="showWidget" :rotate="360" :size="32" :width="5" :value="value2" color="teal" @click="showTaskList = true">
      {{ totalProgress }}
    </v-progress-circular>
    <widget-uploads-list v-model="showTaskList" :tasks="taskList" @start="onStart($event)" />
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      interval: {},
      value: 0,
      value2: 0,
      showTaskList: false,
      text: null,
      timer: null,
      color: null,
      addFiles: [],
      items: [],
      group_id: 1,
      taskList: [],
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    taskList(v) {},
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  },
  created() {
    this.$root.$on("upload-task", (data) => {
      //this.uploadFile(data);
      this.addTask(data);
    });
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  destroyed() {
    // Удаляем обработчик события beforeunload, чтобы избежать утечек памяти

    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  computed: {
    showWidget() {
      return this.taskList.find((t) => {
        return t.status > 0 && t.status != 3;
      })
        ? true
        : false;
    },
    totalProgress() {
      let size = 0;
      let done = 0;
      this.taskList.forEach((t) => {
        size += +t.size;
        done += +t.loaded;
      });
      return size ? Math.floor((done / size) * 100) : 0;
    },
  },
  methods: {
    handleBeforeUnload(e) {
      if (
        this.taskList.find((t) => {
          return t.status > 0 && t.status != 3;
        }) ||
        !true
      ) {
        e.preventDefault();
        console.log(e);
        const confirmationMessage = "У вас есть незавершенные загрузки.\nВы уверены, что хотите покинуть страницу?";
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    addTask(task) {
      let id = this.taskList.length + 1;
      let size = task.file.size;
      //let size = (task.file.size / (1024 * 1024)).toFixed(2);
      this.taskList.push({ id, status: 1, progress: 0, size, loaded: 0, task });
      let idx = this.taskList.findIndex((t) => t.id == id);
      if (idx !== -1) this.runTask(idx);
    },
    runTask(i) {
      this.uploadFile(i);
    },
    onStart(id) {
      if (this.taskList[id].status == 1) this.runTask(id);
    },
    async saveFile(api, data) {
      console.log({ api, data });
      let response = await this.$axios.post(api, data);
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные записаны",
        });
      }
    },
    uploadFile(id) {
      let { file, upload_doc } = this.taskList[id].task;
      if (!upload_doc || !file) {
        this.taskList[id].status = -1;
        return;
      }
      this.taskList[id].status = 2;

      let formData = new FormData();

      formData.append("file", file);
      formData.append("name", file.name);

      formData.append("upload_doc", upload_doc);
      this.$axios
        .post("/file/big/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.taskList[id].loaded = progressEvent.loaded;
          },
        })
        .then((response) => {
          //console.log("file uploaded ok", response);
          this.taskList[id].status = 3;
        })
        .catch(function (error) {
          this.taskList[id].status = -1;
          console.log(error);
          this.$root.$emit("show-info", {
            text: "Ошибка при отправке файла",
            type: "error",
          });
        });
    },
    uploadFile1({ file, api, data }) {
      console.log("uploadFile", { file, api, data });
      if (!api || !data?.id) return;
      let formData = new FormData();

      formData.append("file", file);
      formData.append("name", file.name);
      this.$axios
        .post("/file/big/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.value2 = parseInt((progressEvent.loaded / progressEvent.total) * 100);
          },
        })
        .then((response) => {
          let el = this.items;
          if (!Array.isArray(el)) el = [];
          let f = {
            file: response.data.data.file,
            file_name: file.name,
            category_id: this.model?.category || 1,
            upload_id: response.data.data.id,
            group_id: this.group_id,
            type: "add",
          };
          data["filesAdd"] = [f];
          this.saveFile(api, data);
          this.addFiles.push(f);
          el.splice(0, 0, f);
          this.items = el;
        })
        .catch(function (error) {
          console.log(error);
          this.$root.$emit("show-info", {
            text: "Ошибка при отправке файла",
            type: "error",
          });
        });
    },

    hide(i) {
      this.snackbars.splice(i, 1);
    },
    addAlert(data) {
      const id = Date.now();
      data.id = id;
      this.snackbars = [...this.snackbars, data];
      setTimeout(() => this.clearAlert(id), data.time);
    },
    clearAlert(id) {
      this.snackbars = this.snackbars.filter((alert) => alert.id !== id);
    },
  },
};
</script>
<style lang="scss" scoped>
.alerts {
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 99999;
}
</style>

/** @format 
 * 
 *      "name": "test shop",
      "address": null,
      "phone": null,
      "whatsapp": null,
      "passport": null,
      "patent": null,
      "images": null,
      "rating": null,
      "id": 1,
      "createdon": "2024-03-27T07:52:06.000Z",
      "createdby_id": 1,
      "updatedon": "2024-03-27T07:58:37.000Z",
      "updatedby_id": 1,
      "user_id": null,
      "organization_id": 2,
      "status": 1
*/

const supply = {
  title: 'Кредиты',
  routeName: 'loan',
  accessName: 'loan',
  api: { default: '/mko/loan', 200: '/mko/credit_org/loan' },
  statuses: [
    { value: 1, text: 'Не подтвержден' },
    { value: 2, text: 'Подтвержден' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'shop_name', title: 'Магазин', type: 'string', sortable: true, width: 200 },
    { name: 'vendor_name', title: 'Поставщик', type: 'string', sortable: true, width: 200 },
    { name: 'paid_before', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'amount', title: 'Сумма', type: 'number', sortable: true, width: 120 },
    { name: 'amount_paid', title: 'Наличка', type: 'number', sortable: true, width: 120 },
    { name: 'amount_due', title: 'due', type: 'number', sortable: true, width: 120, hidden: true },
  ],
  form_: [
    { name: 'amount', title: 'Сумма по накладной', type: 'number', validator: ['req'] },
    { name: 'amount_paid', title: 'Сумма оплаты сейчас', type: 'number', validator: ['req'] },
    { name: 'amount_due', title: 'Сумма кредитования', type: 'number', validator: ['req'] },

    {
      name: 'shop_id',
      title: 'Магазин',
      type: 'selectapi',
      api: '/mko/shops',
      itemField: { text: 'shop_name', value: 'shop_id' },
      validator: ['req'],
    },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: '/users/organization/select', validator: [] },
    { name: 'user_id', title: 'Экспедитор', type: 'selectapi', api: '/users/deliveryman/select', validator: [] },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', default: 1, validator: ['req'] },
    { name: 'data' },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
  ],

  config: {
    default: {
      title: 'Поставка',
      fields: 'code,amount,amount_paid,amount_due,amount_loaned,document,shop_id,organization_id,status,user_id',
      fieldsRO: 'code,amount,amount_paid,amount_due,amount_loaned,document,shop_id,organization_id,status,user_id',
      fieldsForm: 'amount_due#4,shop_id#6',
    },
  },
};
export default supply;

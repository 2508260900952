<template>
  <v-dialog ref="dialog" v-model="showPicker" :return-value.sync="date" persistent width="290px" :disabled="readonly">
    <template v-slot:activator="{ on, attrs }">
      <slot>
        <div v-bind="attrs" v-on="on"><a-view-base-date :value="text" /></div>
      </slot>
    </template>
    <v-date-picker v-if="showPicker" v-model="d" full-width>
      <v-spacer></v-spacer>
      <v-btn @click="showPicker = false">Закрыть</v-btn>
      <v-btn
        color="primary"
        @click="
          $emit('input', d);
          $refs.dialog.save(d);
        "
        >Записать</v-btn
      >
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: String,
    text: { type: String, default: "Не указано" },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      d: this.value || "",
      showPicker: false,
    };
  },

  computed: {
    date: {
      get: function () {
        if (!this.d) {
          return new Date().date;
        }
        //  this.text = this.value;

        return this.d;
      },
      set: function (v) {
        this.d = v;
        // this.$emit("input", v);
      },
    },
  },
};
</script>

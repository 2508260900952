<template>
  <div class="py-1">
    <v-expansion-panels accordion v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header style="font-size: 1.25rem; font-weight: 500; letter-spacing: 0.0125em">
          {{ model.title }} [{{ items.length }}]
          <v-spacer />
          <div v-if="panel !== undefined" class="text-right pa-0">
            <v-btn class="ma-0" @click.native.stop="addItem()" color="primary">Добавить</v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="i-o-card px-1 my-2" v-for="(el, i) in items" :key="`item-${i}-${el.name}`" no-gutters>
            <v-col cols="11" class="py-0">
              <a-input-object-techrequirementsItem
                :value="items[i]"
                :model="modelItem"
                @input="onInput($event, i)"
                :config="{ dense: true, readonly, hideDetails: 'auto' }"
              />
            </v-col>
            <v-col cols="1">
              <v-btn :disabled="readonly" fab x-small title="Удалить" @click.prevent="remove(i)" class="mt-2">
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
              <v-btn :disabled="!(readonly || i)" class="my-1" fab x-small title="Удалить" @click.prevent="moveItem(i, el, 1)">
                <v-icon dark> mdi-arrow-up-bold </v-icon>
              </v-btn>
              <v-btn :disabled="!(readonly || i + 1 < items.length)" fab x-small title="Удалить" @click.prevent="moveItem(i, el, 0)">
                <v-icon dark> mdi-arrow-down-bold </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: () => {} },
  },
  data() {
    return {
      modelDef: [
        { name: "name", title: "Название", type: "select", options: "tech_requirements", validator: ["req"] },
        { name: "description", title: "Описание", type: "text", rows: 2 },
      ],
      panel: undefined,
      uploadsFiles: null,
      dialogVideoFile: false,
      dialogFile: false,
      fileLoading: false,
      showError: false,
      err: null,
      over: {},
      startLoc: 0,
      progressLoading: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    modelItem() {
      let res;
      if (this.model?.modelItem) res = this.model?.modelItem;
      else res = this.modelDef;
      return res;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },

    items: {
      get() {
        let d;
        try {
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
        //    this.$emit("input", this.isReverse ? v.reverse() : v);
      },
    },
  },
  methods: {
    onInput(e, i) {
      let d = this.items;
      d[i] = e;
      this.items = d;
    },
    remove(key) {
      let e = this.items;
      e.splice(key, 1);
      this.items = e;
    },
    addItem() {
      let e = this.items;
      e.push({});
      this.items = e;
    },
    moveItem(key, item, pr) {
      let e = this.items;
      e.splice(key, 1);
      e.splice(key + (pr ? -1 : +1), 0, item);
      //  if (!pr) e.splice(key + 1, 1);

      this.items = e;
    },
  },
};
</script>
<style lang="scss">
.i-o-card {
  border-style: solid;
  line-height: 1.1;
  box-sizing: border-box;
  border: 1px solid;
}
.over {
  opacity: 0.6;
}
</style>

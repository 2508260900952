<template>
  <div ref="reader" id="readerQRCode"></div>
</template>
<script>
import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5Qrcode } from "html5-qrcode";
export default {
  props: {
    qrbox: {
      type: Number,
      default: 250,
    },
    fps: {
      type: Number,
      default: 10,
    },
    start: { type: Boolean, default: false },
  },
  data() {
    return {
      reader: null,
      scanReady: false,
    };
  },
  watch: {
    start(v) {
      console.log("start", v);
      if (v) {
        //  this.onStartScan();
      } else {
        this.onStopScan();
      }
    },
  },
  mounted() {
    //this.onStartScan();
  },
  beforeDestroy() {
    console.log("destr");
    this.onStopScan();
  },
  methods: {
    async onStartScan1() {
      const config = {
        fps: this.fps,
        qrbox: this.qrbox,
      };
      if (!this.scanReady) {
        this.reader = new Html5QrcodeScanner("readerQRCode", config);
        await this.reader.render(this.onScanSuccess);
        this.scanReady = true;
      }
    },
    onStartScan() {
      if (this.scanReady) return;
      this.reader = new Html5Qrcode("readerQRCode");
      this.reader.start(
        { facingMode: "environment" },
        {
          fps: this.fps,
          qrbox: this.qrbox,
        },
        (decodedText, decodedResult) => {
          this.onScanSuccess(decodedText, decodedResult);
          this.onStopScan();
        },
        errorMessage => {
          // Обработка ошибок
          // console.error(errorMessage);
        },
      );
      this.scanReady = true;
    },

    async onScanFile(file) {
      let reader = new Html5Qrcode("readerQRCode");
      reader
        .scanFile(file, false)
        .then((decodedText, decodedResult) => {
          console.log("ddd");
          this.onScanSuccess(decodedText, decodedResult);
        })
        .catch(error => {
          this.$root.$emit("show-info", {
            text: "Неверное изображение",
            type: "error",
          });
        });
    },
    onStopScan() {
      if (this.reader) {
        console.log("clear");
        try {
          this.reader.stop().then(() => {
            this.reader.clear();
          });
        } catch (error) {
          console.log("scan clear error");
        }
        this.scanReady = false;
      } else console.log("nothing clear");
    },
    onPauseScan() {
      if (this.reader) {
        this.reader.stop().then(() => {
          console.log("Сканер остановлен");
        });
        try {
        } catch (error) {
          console.log("scan pause error");
        }
      }
    },
    onScanSuccess(decodedText, decodedResult) {
      this.$emit("result", decodedText, decodedResult);
    },
  },
};
</script>

<template>
  <div>
    <v-select
      outlined
      :clearable="!readonly"
      :items="getSortOptions(model)"
      item-text="text"
      item-value="value"
      item-disabled="hidden"
      v-model="v"
      menu-props="offset-y"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :dense="config.dense || false"
      @input1="$emit('input', $event)"
      small-chips
      multiple
      :deletable-chips="config.readonly != true"
      hide-selected
    >
      <template v-slot:selection="data">
        <v-chip
          v-show="data.item.show !== false"
          v-bind="data.attrs"
          :input-value="data.selected"
          :close="config.readonly != true"
          small
          @click:close="data.parent.selectItem(data.item)"
          class="mt-1"
        >
          {{ $t(data.item.text) }}
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
import modelOptions from "../../libs/modelOptions";
export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array],
    values: { type: Object, default: () => {} },
    model: { type: Object, default: () => {} },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: "" },
    config: { type: Object, default: () => {} },
  },
  data() {
    return {
      cc: 0,
      title: null,
      preText: null,
      toggleSelect: false,
    };
  },
  computed: {
    v: {
      get() {
        let res = [];
        if (!this.value) return res;
        if (this.options)
          this.options.forEach((el) => {
            if (this.value & (1 << (el.value - 1))) {
              res.push(el);
            }
          });
        return res;
      },
      set(v) {
        let res = 0;
        v.forEach((el) => {
          //    console.log(el, (1 << (el - 1)).toString(2));
          res ^= 1 << (el - 1);
        });
        this.$emit("input", res);
        // console.log(res.toString(2), v);
      },
    },
    options() {
      return this.getOptions(this.model);
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    titleValue() {
      return this.title || this.model?.title || null;
    },
  },
  methods: {
    getSortOptions(model) {
      let list = JSON.parse(JSON.stringify(this.getOptions(model) || []));
      return list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
  },
};
</script>

<template>
  <v-row
    class="fill-height ma-0 pl-0"
    align="center"
    justify="center"
    style="overflow: auto"
  >
    <v-col
      v-for="(el, key) in items"
      :key="key"
      cols="2"
      class="pa-1"
      :class="{
        'pl-0': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-card class="ma-0" active-class="" width="100%">
        <a
          :href="$root.config.imageUrl + el"
          target="_blank"
          @click.prevent="
            fullScr = el;
            currImage = key;
            fullImage = true;
          "
          ><v-img
            :aspect-ratio="1 / 1"
            :src="
              $root.config.imageUrl + el + '?width=' + size + '&height=' + size
            "
          />
        </a>
      </v-card>
    </v-col>
    <a-view-image-dialog v-if="0 == 10" v-model="fullImage" :src="fullScr" />
    <a-view-image-carousel
      v-else
      v-model="fullImage"
      :src="fullScr"
      :items="items"
      :curr="currImage"
    />
  </v-row>
</template>

<script>
export default {
  props: {
    value: Array,
    size: { type: Number, default: 150 },
  },
  computed: {
    items: {
      get() {
        if (this.value) {
          try {
            return this.value; //JSON.parse(this.value);
          } catch (error) {}
        }
        return [];
      },
    },
  },
  data() {
    return {
      fullImage: false,
      currImage: 0,
      fullScr: "",
    };
  },
};
</script>
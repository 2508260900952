<template>
  <v-dialog :value="value" @input="$emit('input')" fullscreen refs="carousel">
    <v-card style="background-color: #222" flat tile class="d-flex justify-center">
      <v-carousel hide-delimiters v-model="current" height="100vh">
        <v-btn style="position: absolute; right: 30px; top: 30px; z-index: 100" fab dark small color="blue-grey" title="Закрыть окно" @click="doClose()">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>

        <v-btn
          style="position: absolute; left: 30px; top: 30px; z-index: 100"
          fab
          dark
          small
          id="btn-full-screen"
          color="blue-grey"
          title="full screen"
          @click="toggle()"
        >
          <v-icon dark v-if="!fullscreen"> mdi-arrow-collapse </v-icon>
          <v-icon dark v-else> mdi-arrow-expand </v-icon>
        </v-btn>
        <v-carousel-item v-for="(item, i) of items" :key="i">
          <video v-if="item.includes('video#')" controls playsinline style="height: 100vh; display: block; margin: 0 auto" crossorigin="anonymous">
            <source :src="$root.config.videoUrl + item.split('#')[1]" type="video/mp4" crossorigin="anonymous" />
          </video>
          <v-img v-else contain :src="$root.config.imageUrl + item + '?height=1080'" height="100%" />
          <v-btn
            :href="$root.config.imageUrl + item + '?attachment=true'"
            target1="_blank"
            style="position: absolute; left: 30px; top: 90px; z-index: 100"
            fab
            dark
            small
            color="error"
            title="Скачать"
          >
            <v-icon dark> mdi-download </v-icon>
          </v-btn>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    curr: { type: Number, default: 0 },
    items: Array,
    src: String,
  },
  data() {
    return { current: 0, fullscreen: true };
  },
  watch: {
    value(v) {
      if (v) {
        this.current = this.curr;
      }
    },
  },
  created() {
    this.current = this.curr;
  },
  computed: {},
  methods: {
    doClose() {
      if (!this.fullscreen) {
        this.toggle();
      }
      this.$emit("input");
    },
    async toggle() {
      await this.$fullscreen.toggle();
      this.fullscreen = this.$fullscreen.isFullscreen;
    },
  },
};
</script>
<style lang="scss" scope></style>

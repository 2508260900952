<template>
  <div class="d-flex" style="position: relative">
    <v-text-field
      outlined
      :value="value"
      :label="model.title"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :readonly="config.readonly || false"
      @input="onInput"
      :class="classBlock"
      :autofocus="model.autofocus || false"
    >
    </v-text-field>
    <select v-model="addType" style="font-size: 10px">
      <option v-for="(oe, oi) in addTypes" :key="oi" :value="oi">
        {{ oe }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number, Boolean, Object, Array],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data: function () {
    return {
      addType: 0,
      addTypes: ["String", "Number", "Array", "Object", "Boolean"],
    };
  },
  computed: {
    classBlock() {
      if (this.model?.class) return this.model.class;
      return this.config?.class || "";
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
      return;
      console.log("inpt", e, JSON.parse(e));
      this.$emit("input", JSON.parse(e));
    },
    getType: function () {
      switch (this.addType) {
        case 0:
          return "";
        case 1:
          return 0;
        case 2:
          return [];
        case 3:
          return {};
        case 4:
          return false;
      }
    },
  },
};
</script>

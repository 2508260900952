<template>
  <v-btn
    color="primary"
    @click="$emit('click')"
    :disabled="disabled"
    class="px-2"
  >
    <v-icon>far fa-edit</v-icon>
    <btn-title v-if="$vuetify.breakpoint.smAndUp">{{ title }}</btn-title>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Редактировать" },
    disabled: { type: Boolean, default: false },
  },
};
</script>
<template>
  <div>
    <v-text-field
      outlined
      :value="value"
      :label="model.title"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      @input="$emit('input', $event)"
      :class="model.class || config.class"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
};
</script>
<template>
  <div>
    <v-badge :color="color" dot :value="badge">{{ v }}</v-badge>
  </div>
</template>
 
<script>
import { modelOptions } from "./../../../mixings";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: [Object],
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      badge: false,
      color: "",
    };
  },
  computed: {
    v() {
      let prop = this.getElementByValue(
        this.model?.masterField
          ? this.values?.[this.model.masterField]
          : this.value,
        this.model
      );

      if (prop) {
        this.badge = 1;
        this.color = prop.color;
      }
      return this.value;
    },
  },
};
</script>
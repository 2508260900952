<template>
  <span>{{ value }}</span>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
};
</script>
<template>
  <select class="f-search" autocomplete="on" :value="value" @input="$emit('input', $event.target.value)" @change="$emit('change', $event.target.value)">
    <option value="">{{ $t("All") }}</option>
    <option v-for="(el, i) in data" :key="i" :value="el.value" :selected="value == el.value">
      {{ el.text || el.value }}
    </option>
  </select>
</template>

<script>
import modelOptions from "@/components/libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    model: Object,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.fitchData();
  },
  methods: {
    async fitchData() {
      let params = {};
      if (this.model.sort) {
        params.sort = this.model.sort;
      }
      if (this.model.filters) {
        params.filters = this.model.filters;
      }
      let response = await this.$axios.get(this.model.api, { params });
      if (this.model.sort) {
      }
      this.data = response.data.data.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
  },
};
</script>
<style lang="scss">
@import "styles.scss";
</style>

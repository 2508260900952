<template>
  <v-dialog :value="value" @input="$emit('input')" fullscreen>
    <v-card>
      <v-card-text>
        <v-row
          justify="center"
          style="
            position: fixed;
            left: 10px;
            right: 10px;
            bottom: 10px;
            top: 10px;
            background-color: #222;
          "
        >
          <v-btn
            style="position: absolute; right: 30px; top: 30px; z-index: 100"
            fab
            dark
            small
            color="blue-grey"
            title="Закрыть окно"
            @click="$emit('input')"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
          <v-img aspect-ratio="2" contain :src="$root.config.imageUrl + src" />
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    src: String,
  },
};
</script>
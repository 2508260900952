/** @format 
 * 
 *      "name": "test shop",
      "address": null,
      "phone": null,
      "whatsapp": null,
      "passport": null,
      "patent": null,
      "images": null,
      "rating": null,
      "id": 1,
      "createdon": "2024-03-27T07:52:06.000Z",
      "createdby_id": 1,
      "updatedon": "2024-03-27T07:58:37.000Z",
      "updatedby_id": 1,
      "user_id": null,
      "organization_id": 2,
      "status": 1
*/

const shopLink = {
  title: 'Магазины поставщика',
  routeName1: 'shop_view',
  accessName: 'shopLink',
  api: '/mko/vendor/shops',

  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'vendor_name', title: 'Поставщик', type: 'string', sortable: true, filter: { name: 'vendor_id', type: 'selectapi', api: '/mko/vendor/select' }, width: 300 },
    { name: 'shop_name', title: 'Магазин', type: 'string', sortable: true, width: 300 },
    { name: 'deliveryman_name', title: 'Экспедитор', type: 'string', sortable: true, width: 300 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 120 },
  ],
  form: [
    { name: 'vendor_id', title: 'Поставщик', type: 'selectapi', api: '/mko/vendor/select', validator: ['req'] },
    {
      name: 'shop_id',
      title: 'Магазин',
      type: 'selectapi',
      api: '/mko/shop/select',
      itemField: {
        text_func:
          '(d)=>{let res = d.text; try {let addr=JSON.parse(d?.address_fact || `false`); let a=``; if (addr) a=`(${addr.city}, ${addr.address})`; res = `#${d.value}  ${d.text} ${a}`;} catch (error) {console.error(error)}; return res;}',
      },
      validator: ['req'],
    },
    { name: 'deliveryman_id', title: 'Экспедитор', type: 'selectapi', api: '/users/deliveryman/select', validator: [] },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 0, validator: ['req'] },
  ],

  config: {
    default: {
      title: 'Магазин',
      api: '/mko/vendor/shops',
      fields: 'vendor_id,shop_id,deliveryman_id,status',
      fieldsRO: '',
      fieldsForm: 'shop_id,deliveryman_id',
    },
    role_100: {
      title: 'Магазин',
      api: '/mko/vendor/shops',
      fields: 'vendor_id,shop_id,deliveryman_id,status',
      fieldsRO: '',
      fieldsForm: 'shop_id,deliveryman_id',
    },
    role_1000: {
      api: '/mko/admin/vendor/shops',
      title: 'Магазин',
      fields: 'vendor_id,shop_id,deliveryman_id,status',
      fieldsRO: '',
      fieldsForm: 'shop_id,deliveryman_id',
    },
    role_50: {
      api: '/mko/deliveryman/vendor/shops',
      title: 'Магазин',
      fields: 'vendor_id,shop_id,deliveryman_id,status',
      fieldsRO: '',
      fieldsForm: 'shop_id,deliveryman_id',
    },
  },
};
export default shopLink;

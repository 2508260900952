<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-badge v-if="badge" :content="(qrCodeList && qrCodeList.length) || 0" overlap color="red">
        <v-btn color="primary" :disabled="disabled" v-bind="attrs" v-on="on">
          <v-icon>fas fa-qrcode</v-icon>
        </v-btn>
      </v-badge>
      <v-btn v-else color="primary" :disabled="disabled" v-bind="attrs" v-on="on">
        <v-icon>fas fa-qrcode</v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-tabs v-model="tab" centered icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="t in tabs" :key="t.name" :href="`#${t.name}`">
          {{ t.title }}
          <v-icon>{{ t.icon }}</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="scan">
          <v-card flat>
            <v-card-title class="text-h5"> Загрузите код </v-card-title>
            <v-card-text class="text-center">
              <v-btn color="primary" @click="onCamera" class="mr-1"> <v-icon>fas fa-camera</v-icon> </v-btn>
              <v-file-input ref="fileImage" v-show="false" accept="image/*" type="file" v-model="imageFile" hide-input @change="scanImage()" />
              <v-btn color="primary" @click="onImage()"> <v-icon>fas fa-image</v-icon> </v-btn>
              <a-qrcode-scanner ref="scanner" v-if="dialog" :qrbox="250" :fps="10" style1="width: 450px" @result="onScan" :start="tab == 'scan'" class="mt-1" />
              <v-text-field label="Название" v-model="cryptName" class="my-2" outlined dense readonly hide-details="auto" />
              <v-textarea v-model="cryptCode" rows="2" readonly dense outlined hide-details="auto" />
            </v-card-text>
            <v-card-actions>
              <v-btn color="red darken-1" text @click="dialog = false"> Закрыть </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="saveQR()" :disabled="!cryptCode">Сохранить</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item value="create">
          <v-card flat>
            <v-card-title class="text-h5"> Создать </v-card-title>
            <v-card-text>
              <a-qrcode :value="cryptQR" :params="{ width: 300, errorCorrectionLevel: 'L' }" />
              <v-text-field label="Название" v-model="cryptName" outlined dense :error-messages="errorsName" @change="cryptQR = ''" />
              <v-textarea label="Код шифрования" v-model="cryptCode" outlined rows="2" dense :error-messages="errorsCode" @change="cryptQR = ''" />
              <div class="d-flex">
                <v-btn color="primary" @click="cryptCode = genRandom(codeLen)">
                  <v-icon>fas fa-keyboard</v-icon>
                </v-btn>
                <v-slider v-model="codeLen" :thumb-size="24" thumb-label="always" max="128" min="16"></v-slider>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="red darken-1" text @click="dialog = false"> Закрыть </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="createQR()">Создать</v-btn>
              <v-btn text @click="saveQR()" :disabled="!cryptQR">Сохранить</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item value="list">
          <v-card flat>
            <v-card-title class="text-h5"> Список кодов </v-card-title>
            <v-card-text> </v-card-text>
            <div v-if="false" class="col-sm-12">
              <div v-for="(item, index) in qrCodeList" :key="index" class="d-flex">
                <div class="col-sm-3">{{ item.name }}</div>
                <div class="col-sm-9">{{ item.code }}</div>
                <v-divider />
              </div>
            </div>
            <v-list v-else dense>
              <v-list-item-group v-model="selectedCode" color="primary">
                <v-list-item
                  v-for="(item, index) in qrCodeList"
                  :key="index"
                  @click="
                    $emit('select-code', item);
                    dialog = false;
                  "
                >
                  <v-list-item-content>
                    <div class="col-sm-3">{{ item.name }}</div>
                    <div class="col-sm-9">{{ item.code }}</div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div class="col-sm-8"></div>

            <v-card-actions>
              <v-btn color="red darken-1" text @click="dialog = false"> Закрыть </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    badge: { type: Boolean, default: false },
    type: { type: String, default: "" },
    title: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedCode: null,
      tab: this.type || "create",
      imageFile: null,
      showScan: false,
      cryptQR: null,
      cryptCode: null,
      cryptName: null,
      dialog: false,
      message: [],
      //qrCodeList: [],
      errorsName: null,
      errorsCode: null,
      codeLen: 64,
    };
  },
  watch: {
    dialog(v) {
      if (v) this.tab = "create";
      this.reset();
    },
    tab() {
      this.reset();
    },
  },
  computed: {
    tabs() {
      return [
        { name: "create", title: "Создать", icon: "mdi-qrcode-plus" },
        { name: "scan", title: "Сканировать", icon: "mdi-qrcode-scan" },
        { name: "list", title: `Список (${this.qrCodeList.length})`, icon: "mdi-qrcode" },
      ];
    },
    qrCodeList: {
      get() {
        return this.$root.qrCodeList || [];
      },
      set(v) {
        this.$root.qrCodeList = v;
      },
    },
  },
  methods: {
    onCamera() {
      this.reset();
      this.$refs.scanner.onStartScan();
    },
    onImage() {
      this.reset();
      this.$refs.scanner.onStopScan();
      const fileInput = this.$refs.fileImage.$el.querySelector("input");
      fileInput.click();
    },

    scanImage() {
      if (!this.imageFile) return;
      this.$refs.scanner.onScanFile(this.imageFile);
    },
    reset() {
      this.cryptName = null;
      this.cryptCode = null;
      this.cryptQR = null;
    },

    genRandom(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=~";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    },
    createQR() {
      this.cryptQR = null;
      let code = { name: this.cryptName, code: this.cryptCode, own: this.$root.profile.id };
      this.errorsName = !code.name || (code.name || "").length > 50 ? "Неверней ввод" : "";
      this.errorsCode = !code.code || (code.code || "").length > 128 || (code.code || "").length < 16 ? "Неверней ввод" : "";
      if (this.errorsName || this.errorsCode) return;
      this.cryptQR = JSON.stringify(code);
    },
    saveQR() {
      let d = [...this.qrCodeList];
      d.push({ name: this.cryptName, code: this.cryptCode });
      this.qrCodeList = [...d];
      this.$root.$emit("show-info", {
        text: "Ключ сохранен в памяти",
      });
      this.reset();
      //this.dialog = false;
    },
    onScan(decodedText, decodedResult) {
      this.$refs.scanner.onPauseScan();
      try {
      } catch (error) {}
      this.showScan = false;
      let code;

      try {
        code = JSON.parse(decodedText);
        if (code.name || code.code) {
          this.cryptName = code.name;
          this.cryptCode = code.code;
        }
      } catch (error) {}
      console.log(decodedText, decodedResult);
    },
  },
};
</script>

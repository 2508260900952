<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth="'1000px'">
      <template v-slot:title> просмотр </template>
      <v-row style="height: 500px">
        <v-col cols="12" class="pb-0">
          <a-table-f-base v-if="!loading" :data="data" :model="model" :searchable="false">
            <template v-slot:top>
              <slot name="top" />
            </template>
            <template v-for="el in model" v-slot:[getSlotName(el)]="{ item, row, field }">
              <slot :name="getSlotName(el)" :item="item" :row="row" :field="field">
                <tree-table-item @change="onChange($event, item, row, field)" class="item" :key="index" :field="field" :index="index" :el="item" :row="row" :canEdit="canEdit">
                </tree-table-item>
              </slot>
            </template>
          </a-table-f-base>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :title="'Сохранить'"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import treeTableItem from "@/views/tree/treeTableItem";

export default {
  name: "tree-table",
  components: {
    treeTableItem,
  },
  props: {
    el: [Array],
    index: [Array, Object, String, Number],
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      loading: true,
      open: false,
      isEdited: false,
      value: "",
      addType: 0,
      addTypes: ["String", "Number", "Array", "Object"],
      drag_src_el: null,
      items: [],
      keys: [],
    };
  },
  computed: {
    data: {
      get() {
        return this.items;
      },
      set: function (v) {
        this.value = v;
      },
    },
    headers() {
      const head = function (key) {
        return { value: key, text: key };
      };
      return this.keys.map(head);
    },
    model() {
      const head = function (key) {
        return { name: key, title: key, type: "variant" };
      };
      let m = this.keys.map(head);
      m.unshift({ name: "tbl_sort", title: "#", type: "variant" });
      return m;
    },
  },
  created() {
    this.makeData();
  },
  methods: {
    getSlotName(model) {
      return `item.` + model.name;
    },
    onClickRow(event) {
      console.log("onClickRow", event);
      if (event.event.button == 0) this.$emit("click", event);
    },
    makeData() {
      let data = [];
      for (let i = 0; i < this.el.length; i++) {
        let el = this.el[i];

        if (typeof el === "object") {
          let tmp = JSON.parse(JSON.stringify(el));
          tmp.tbl_idx = i;
          tmp.tbl_sort = i;
          data.push(tmp);
          for (const key of Object.keys(el)) {
            if (!this.keys.includes(key)) this.keys.push(key);
          }
        }
      }
      this.items = data;
      this.loading = false;
    },
    submit() {
      let data = this.items
        .sort((a, b) => {
          return (a.tbl_sort > b.tbl_sort) - (b.tbl_sort > a.tbl_sort);
        })
        .map((item) => {
          delete item.tbl_idx;
          delete item.tbl_sort;

          return item;
        });
      //delete data.tbl_idx;
      //delete data.tbl_sort;
      this.$emit("change", data);
      this.$emit("input");
    },
    onChange: function (e, item, row, field) {
      console.log({ e, item, row, field });
      let t = this.items;
      t[row.tbl_idx][field.name] = e;
      console.log(this.items);
      this.$forceUpdate();
      return;
      this.$emit("change", t);
      this.$forceUpdate();
    },

    onClickEdit: function () {
      this.isEdited = true;
      this.$nextTick(() => this.$refs.inputEl.focus());
    },
  },
};
</script>

<style lang="scss">
.v-tree-item {
  display: inline;
}
.v-tree-item-li {
}
.v-tree-item-caption {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}
</style>

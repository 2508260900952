/** @format */

import axios from '../../libs/axios';
import { models } from '@/config/models';
const state = {
  newComment: 0,
};

const getters = {

  getNewComment: (state, name) => {
    return state.newComment;
  },
};

const actions = {};

const mutations = {
  setNewComment: (state) => {
    state.newComment++;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <dialog-edit2 v-model="showDialog" :maxWidth="'600px'">
      <template v-slot:title> Настройки </template>
      <v-row class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" :cols="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, hideDetails: 'auto' }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :title="'Сохранить'"></a-btn-save>
        <v-btn @click="close">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm],

  props: {
    value: Boolean,
    fields: [Array],
    table: { type: String, default: "" },
  },
  data() {
    return {
      showDialog: false,
      modelForm: null,
      model: [],
    };
  },
  computed: {},
  created() {},
  watch: {
    showDialog(v) {
      if (!v) this.resetFrom();
    },
  },

  methods: {
    show() {
      if (!this.table) return;
      this.showDialog = true;
      this.calcModel();
    },
    close() {
      this.showDialog = !true;
    },
    getName(n) {
      return n.replace(/\./g, "~~");
    },
    calcModel() {
      let fields = this?.fields;
      if (!fields) return;
      let model = [];
      let form = "";
      fields.forEach((f) => {
        let name = this.getName(f.name);
        if (!f?.hidden) {
          model.push({ name: `FieldName-${name}`, title: "Столбец", type: "string", default: f.title, readonly: true });
          model.push({
            name: `FieldWidth-${name}`,
            title: "размер",
            type: "number",
            default: this.$store.getters["config/getLocalParams"]?.[this.table + ".FieldWidth." + f.name] || f.width || 100,
            prop: `${this.table}.FieldWidth.${f.name}`,
            validator: ["req", "min", "max"],
            min: 10,
            max: 500,
          });
          model.push({
            name: `FieldColor-${name}`,
            title: "Цвет",
            type: "colorui",
            default: this.$store.getters["config/getLocalParams"]?.[this.table + ".FieldColor." + f.name] || f.color || null,
            prop: `${this.table}.FieldColor.${f.name}`,
          });
          model.push({
            name: `FieldHidden-${name}`,
            title: "скрыть",
            type: "switcher",
            default: this.$store.getters["config/getLocalParams"]?.[this.table + ".FieldHidden." + f.name] || null,
            prop: `${this.table}.FieldHidden.${f.name}`,
          });
          form += (form ? "," : "") + `FieldName-${name}#5,FieldWidth-${name}#3,FieldHidden-${name}#2,FieldColor-${name}#2`;
        }
      });
      let mForm = [];
      (form.split(",") || []).forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });
      this.modelForm = mForm;
      this.fillFormFromModel(model);
      this.model = model;
      this.fetchData();
    },
    fetchData() {
      this.oldData = Object.assign({}, this.data);
    },
    async post() {
      for (let m of this.model) {
        if (!m.prop) continue;
        if (this.data[this.getName(m.name)] === null && this.oldData[this.getName(m.name)] === null) continue;
        this.$store.commit("config/setLocalParams", {
          name: m.prop,
          value: this.data[this.getName(m.name)],
        });
      }
      this.afterSave(this.data, status);
    },
    afterSave(data, status) {
      this.close();
    },
  },
};
</script>

<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="'100hv'">
      <template v-slot:title> РЕДАКТИРОВАНИЕ </template>
      <v-card-text style="height: 80vh; overflow: auto" class="pa-0">
        {{ config }}
        <v-card-text style="height: 300px; overflow: auto" class="pa-0">
          <a-table-f-data2
            v-if="loaded"
            ref="table"
            :dataTable="data"
            :model="model"
            :searchable="false"
            :defaults="{
              sort: { key: 'id', order: 'DESC' },
            }"
            @click="onClickRow($event)"
          >
          </a-table-f-data2>
        </v-card-text>
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <s-editor-editItem v-if="showEdit" v-model="showEdit" :el="editValue" @change="save($event)" />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    config: [Object],
    value: Boolean,
    activate: Boolean,
  },
  data() {
    return {
      title: "",
      loaded: false,
      showEdit: false,
      editField: null,
      editValue: null,
      editId: null,
      url: "/config",
      defaults: {},
      data: [],
    };
  },
  created() {
    window.addEventListener("keydown", this.pressButtonEvent);
  },
  destroyed() {
    window.removeEventListener("keydown", this.pressButtonEvent);
  },
  watch: {
    value(v) {
      if (v) {
        this.fitchData();
      } else {
        this.loaded = false;
      }
    },
  },
  computed: {
    model() {
      const head = function (key) {
        return { name: key, title: key, type: "variant" };
      };
      let m = [];
      if (this.data.length) {
        let data = this.data[0];
        for (const key in data) {
          m.push({ name: key, title: key, type: "variant" });
        }
      }
      return m;
    },
  },

  methods: {
    pressButtonEvent(e) {
      if (!this.value)
        if (e.keyCode == 114 && e.ctrlKey) {
          e.preventDefault();
          this.data = this.dataEdit;
          // this.value = true;
          //this.show = true;
          this.$emit("show");
          return false;
        }
    },
    async save(e) {
      if (!this.config) return;
      if (e === undefined) return;

      let config = this.config;
      let table = config?.table || "";
      if (!table) return;
      let val;
      if (typeof e === "number") {
        val = Number(e);
      } else if (typeof e === "string") {
        val = `'${String(e)}'`;
      } else if (typeof e === "boolean") {
        val = "true" || e === true ? (e = true) : (e = false);
      } else if (typeof e === "object") {
        if (Array.isArray(e)) val = JSON.parse("[" + e + "]");
      }
      let sql = `update  ${table} set ${this.editField}=${val} where id=${this.editId}`;
      console.log("sql", e, typeof e, sql);
      //return;
      await this.$axios.post("/accounting/records/report-sql", { q: sql });
      this.fitchData();
    },
    async fitchData() {
      if (!this.config) return;
      let config = this.config;
      let filter = config?.filter || {};
      let table = config?.table || "";
      if (!table) return;
      let where = this.getWhere(filter);
      console.log("where", where, filter);
      let sql = "select ";
      const resp = await this.$axios.post("/accounting/records/report-sql", {
        q: `select *  from ${table} ${where}`,
      });
      if (!(resp.data.status == "ok")) {
        this.$root.$emit("show-info", {
          text: "Ошибка....",
        });
        return;
      }
      this.data = resp.data.data;
      this.loaded = true;
    },
    onClickRow(d) {
      console.log("click", d);
      this.editField = d.field.name;
      this.editValue = d.row[d.field.name];
      this.editId = d.row.id;
      this.showEdit = true;
      //this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    onChange(e) {
      console.log("onChange:", e);
      this.data[e.i] = e.v;
    },
    onCreate(e) {
      console.log("onCreate:", e);
      this.data[e.i] = e.v;

      // this.data[e.i] = e.v;
    },
    onRemove(e) {
      console.log("onRemove:", e);
      this.data[e.i] = e.v;
    },
    async post() {
      //this.data.models = { users: models.users };
      let data = { config: this.data };

      this.loading = true;
      let response = await this.$axios.post(this.url, data);
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Данные записаны",
        });
      }
    },
    getWhere(f, w = 0) {
      let where = "";
      for (const key in f) {
        if (typeof f[key] === "string" && f[key] !== "") {
          where += where ? " and " : "";
          where += `${key} = '${f[key]}'`;
        } else if (Array.isArray(f[key])) {
          let a = "";
          f[key].forEach((el) => {
            a += a ? " and " : "";
            a += this.getWhere({ [key]: el }, 1);
          });
          where += where ? " and " : "";
          where += `(${a})`;
        } else if (typeof f[key] === "number") {
          where += where ? " and " : "";
          where += `${key} = ${f[key]}`;
        } else if (typeof f[key] === "object") {
          where += where ? " and " : "";
          where += `${key} ${f[key].condition} '${f[key].value}' `;
        }
      }
      if (!w) {
        if (this.query?.where) {
          where += where ? " and " : "";
          where += this.query.where;
        }
        where = where ? "WHERE " + where : "";
      }
      return where;
    },
  },
};
</script>

<template>
  <div>
    <v-autocomplete
      outlined
      :clearable="!readonly"
      :disabled="disabled"
      :items="roles"
      v-model="value_roles"
      :label="model.title + ' для роли'"
      :dense="config.dense || false"
      :readonly="readonly"
      item-text="text"
      item-value="value"
      small-chips
      multiple
      deletable-chips
      hide-selected
    >
    </v-autocomplete>
    <v-autocomplete
      outlined
      :clearable="!readonly"
      :disabled="disabled"
      :items="users"
      v-model="value_users"
      :label="model.title + ' для пользователя'"
      :dense="config.dense || false"
      :readonly="readonly"
      item-text="text"
      item-value="value"
      small-chips
      multiple
      deletable-chips
      hide-selected
    >
    </v-autocomplete>
  </div>
</template>

<script>
import modelOptions from "../../libs/modelOptions";
export default {
  mixins: [modelOptions],
  props: {
    value: [Array, Number, Object],
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    model: Object,
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    value_roles: {
      get() {
        let res = this.value || [];
        return res.filter((v) => v.type == 2).map((v) => v.role);
      },
      set(v) {
        let res = [
          ...v.map((e) => {
            return { type: 2, role: e };
          }),
          ...this.value_users.map((e) => {
            return { type: 1, user_id: e };
          }),
        ];
        this.$emit("input", res);
      },
    },
    value_users: {
      get() {
        let res = this.value || [];
        return res.filter((v) => v.type == 1).map((v) => v.user_id);
      },
      set(v) {
        let res = [
          ...this.value_roles.map((e) => {
            return { type: 2, role: e };
          }),
          ...v.map((e) => {
            return { type: 1, user_id: e };
          }),
        ];
        this.$emit("input", res);
      },
    },
    users() {
      let res = this.$root.dirs.user;
      return res;
    },
    roles() {
      let res = this.$root.dirs.roles;
      return res;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.data = this.getOptions(this.model);
      this.loading = false;
      console.log("data", this.data);
    },
  },
};
</script>
<style lang="scss"></style>

<template>
  <v-menu v-model="popumMenuShow" :position-x="x" :position-y="y" absolute offset-y>
    <v-list v-if="items" class="py-0">
      <v-list-item v-for="(item, index) in items" :key="index" v-if="!item.hidden" dense :disabled="item.disabled" @click="onClick(item)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data: function () {
    return {
      popumMenuShow: false,
      popupMenuList: [],
      popupMenuPosition: { x: 0, y: 0 },
      itemId: null,
      itemValue: null,
      menuItems: [],
      contextData: null,
      timer: null,
    };
  },

  created() {},
  watch: {},
  computed: {
    x() {
      return this.popupMenuPosition?.x || 0;
    },
    y() {
      return this.popupMenuPosition?.y || 0;
    },
  },
  methods: {
    showMenu(e, model = null) {
      e.preventDefault();
      this.contextData = model;
      clearTimeout(this.timer);
      setTimeout(() => {
        this.popupMenuPosition = { x: e.clientX, y: e.clientY };
        this.popumMenuShow = true;
      }, 150);
      this.timer = setTimeout(() => {
        this.popumMenuShow = false;
      }, 4000);
    },
    onClick(item) {
      this.$emit("click", item);
      this.$emit("clickData", { item, data: this.contextData });
    },
  },
};
</script>

<template>
  <v-menu offset-y v-if="items.length">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="disabled" fab x-small>
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index" link @click="$emit('click', item)">
        <v-list-item-title>{{ item.action }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Статус" },
    disabled: { type: Boolean, default: false },
    items: { type: Array, default: [] },
    icon: { type: String, default: "mdi-debug-step-over" },
  },
};
</script>

<template>
  <span>
    <span v-if="model.preText && value"> {{ model.preText }} </span>
    {{ value ? text || `  ?${value}?` : "" }}
  </span>
</template>

<script>
import { modelOptions } from "../../mixings";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: [],
      text: null,
      preText: null,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    value(v) {
      if (v) this.fetchData();
    },
  },
  methods: {
    getApi(model) {
      let api = "";
      if (model?.apiVar) {
        const key = model.apiVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const name = model.apiVar.apiName.find((el) => {
            return el.value == v;
          })?.name;
          if (name) api = name;
        }
        return api;
      }
      return model.api;
    },
    async fetchData() {
      if (!this.value) {
        this.text = "";
        return;
      }
      let api = await this.getApi(this.model);

      let type = "get";
      let d = {};
      if (typeof api === "object") {
        type = api.type || type;
        if (api.data) {
          d.name = api.data?.name;
          d.params = {};
          for (const key in api.data.params) {
            if (typeof api.data.params[key] === "string" && api.data.params[key].includes("function(d)")) {
              let func = eval(api.data.params[key]);
              d.params[key] = func(this.values) || 0;
            } else d.params[key] = api.data.params[key];
          }
        }

        //data = api.data || null;
        api = api.api;
      }
      if (!api) return;

      let t = this.model?.itemField?.text || "text";
      let v = this.model?.itemField?.value || "value";
      let filters;
      if (this.model?.itemField) {
        filters = { [v]: this.value };
      }
      this.loading = true;
      let params = { filters };
      let response = type == "get" ? await this.$axios.get("/" + api, { params }) : await this.$axios.post(api, d);
      // let response = await this.$axios.get("/" + api, { params: { filters } });
      this.loading = false;

      let data = response.data.data.find((el) => el[v] == this.value);
      this.text = data?.[t];
    },
    async fetchData1() {
      let api = await this.getApi(this.model);
      if (!api) return;
      this.loading = true;

      let response = await this.$axios.get(api);
      this.loading = false;
      this.data = response.data.data;
    },
  },
};
</script>

<template>
  <select class="f-search" :value="value"  @change="$emit('change', $event.target.value)">
    <option value="">{{ $t("All") }}</option>
    <option v-for="(el, i) in getSortOptions(model)" :key="i" :value="el.value">{{ el.text }}</option>
  </select>
</template>

<script>
import modelOptions from "@/components/libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array],
    model: Object,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getSortOptions(model) {
      let list = JSON.parse(JSON.stringify(this.getOptions(model) || []));
      return list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
  },
};
</script>
<style lang="scss">
@import "styles.scss";
</style>

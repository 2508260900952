<template>
  <div>
    <div>
      <div class="phone">
        <div v-if="phoneBar" class="phone-el">{{ status }}</div>
        <div v-if="phoneBar" class="phone-el">
          <input
            id="caller-phone_number"
            v-model="phone_number"
            class="form-phone mx-0"
            style="width: 150px"
          />
        </div>
        <div v-if="phoneBar" class="phone-el">
          <button
            class="btn btn-default call-btn call-btn-i"
            @click="canRing = !canRing"
            :title="canRing ? 'Выключить звонок' : 'Включить звонок'"
          >
            <i class="fa" :class="canRing ? 'fa-bell' : 'fa-bell-slash'"></i>
          </button>
          <button
            class="btn btn-default call-btn call-btn-i"
            :disabled="!session"
            @click="muted = !muted"
            :title="muted ? 'Включить звук' : 'Выключить звук'"
          >
            <i
              class="fa"
              :class="muted ? 'fa-microphone-slash' : 'fa-microphone'"
            ></i>
          </button>
          <v-btn
            v-if="canCall"
            class="btn btn-default call-btn"
            :class1="{ 'call-btn-start': phone_number.length > 3 }"
            fab
            color="green"
            :disabled="phone_number.length < 4"
            @click="startCall()"
            title="Позвонить"
          >
            <i class="fas fa-phone"></i>
          </v-btn>
          <button
            v-else-if="canCall && 0"
            class="btn btn-default call-btn"
            :class="{ 'call-btn-start': phone_number.length > 3 }"
            :disabled="canCall && phone_number.length > 3"
            @click="startCall()"
            title="Позвонить"
          >
            <i class="fas fa-phone"></i>
          </button>
          <button
            v-else
            class="btn btn-default call-btn call-btn-stop"
            @click="endCall()"
            title="Положить трубку"
          >
            <i class="fas fa-phone"></i>
          </button>
        </div>
        <phone-inbox-form
          v-if="showInbox"
          v-model="showInbox"
          :phone="remoteNumber"
          :canCall="canCall"
          @answer="onAnswer()"
          @end-call="endCall()"
        />
        <button
          class="btn btn-default call-btn call-btn-i"
          @click="setPhoneBar()"
          :title="phoneBar ? 'скрыть панель' : 'показать телефон'"
        >
          <i
            class="fa"
            :class="{
              'fa-mobile-alt': !phoneBar,
              'fa-times': phoneBar,
            }"
          >
          </i>
        </button>
      </div>
      <audio id="audio_remote" autoplay="autoplay"></audio>
      <audio id="audio_ringtone" loop src="/rington.mp3"></audio>
    </div>
  </div>
</template>
      
<script>
import JsSIP from "./jssip-3.2.4.min.js";

export default {
  props: {
    server: String,
    password: String,
    user: String,
    ws: String,
  },
  data: function () {
    return {
      showInbox: false,
      remoteNumber: "",
      phone_number: "",
      status: "",
      muted: false,
      ua: null,
      uri: null,
      canCall: true,
      canRing: true,
      session: null,
      sipCallTimer: null,
      sipRingTone: null,
      options: null,
    };
  },
  mounted() {
    let app = this;
    this.$root.$on("global-call", (e) => {
      this.phone_number = e;
      this.startCall();
    });
    this.sipRingTone = document.getElementById("audio_ringtone");
    this.uri = "sip:" + this.user + "@" + this.server;
    var socket = new JsSIP.WebSocketInterface(this.ws);
    socket.via_transport = "wss";

    var configuration = {
      session_timers: false,
      use_preloaded_route: false,
      sockets: [socket],
      uri: this.uri,
      password: this.password,
      realm: this.server,
    };

    var eventHandlers = {
      progress: (e) => {
        this.status = "Соединяем";
      },
      failed: (e) => {
        this.endCall();
      },
      ended: (e) => {
        this.endCall();
      },
      confirmed: (e) => {
        this.startTimer();
      },
      muted: (e) => {
        this.muted = true;
      },
      unmuted: (e) => {
        this.muted = false;
      },
    };
    var pcConfig = {
      rtcpMuxPolicy: "negotiate",
      iceServers: [
        {
          urls: [
            "stun:stun.l.google.com:19302",
            "stun:stun1.l.google.com:19302",
            "stun:stun2.l.google.com:19302",
            "stun:stun3.l.google.com:19302",
            "stun:stun4.l.google.com:19302",
          ],
        },
      ],
    };
    this.options = {
      eventHandlers: eventHandlers,
      pcConfig: pcConfig,
      mediaConstraints: { audio: true, video: false },
      rtcOfferConstraints: { offerToReceiveAudio: 1 },
    };

    this.ua = new JsSIP.UA(configuration);

    this.ua.on("connected", (e) => {
      app.status = "Готов";
    });
    this.ua.on("disconnected", (e) => {
      app.status = "Нет соединения";
    });
    this.ua.on("registered", (e) => {});
    this.ua.on("unregistered", (e) => {
      app.status = "Не зарегистрирован";
    });
    this.ua.on("registrationFailed", (e) => {
      app.status = "Ошибка регистрации";
    });
    this.ua.on("newRTCSession", function (ev) {
      if (ev.originator === "local") {
        var peerconnection = ev.session.connection;
        peerconnection.addEventListener("addstream", function (e) {
          document.getElementById("audio_remote").srcObject = e.stream;
        });
        return;
      }

      if (!app.canCall) {
        ev.session.terminate(app.options);
        return;
      }
      if (!app.canRing) {
        ev.session.terminate(app.options);
        return;
      }
      var s = ev.session;
      app.session = s;
      app.remoteNumber = ev.request.from.display_name;
      app.startRingTone();
      app.showInbox = true;

      s.on("peerconnection", (p) => {
        p.peerconnection.addEventListener("addstream", function (e) {
          document.getElementById("audio_remote").srcObject = e.stream;
        });
      });
      s.on("failed", (e) => {
        app.stopRingTone();
        app.showInbox = false;
      });
      s.on("accepted", (e) => {
        app.stopRingTone();
      });
      s.on("ended", (e) => {
        app.endCall();
      });
    });
    this.ua.start();
  },
  watch: {
    muted: function () {
      if (this.muted) {
        this.session.mute();
      } else {
        this.session.unmute();
      }
    },
    canCall: function (v) {
      this.$emit("call-status", v);
    },
  },
  computed: {
    phoneBar: {
      get() {
        return this.$store.getters["config/getPhoneBar"];
      },
    },
  },
  methods: {
    setPhoneBar() {
      this.$store.commit("config/setPhoneBar", {});
    },
    n: function (n) {
      return n > 9 ? "" + n : "0" + n;
    },

    closeMenu: function () {
      this.showInbox = false;
    },

    endCall() {
      this.canCall = true;
      clearInterval(this.sipCallTimer);
      this.ua.terminateSessions();
      this.session = null;
      this.status = "Готов";
    },

    startTimer: function () {
      this.status = "Разговор 00:00";

      var start = new Date().getTime();
      clearInterval(this.sipCallTimer);
      this.sipCallTimer = setInterval(() => {
        var now = new Date().getTime();
        var distance = now - start;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.status = "Разговор " + this.n(minutes) + ":" + this.n(seconds);
      }, 1000);
    },

    startRingTone: function () {
      try {
        this.sipRingTone.currentTime = 0;
        this.sipRingTone.play();
      } catch (e) {
        console.log(e);
      }
    },

    stopRingTone: function () {
      try {
        this.sipRingTone.pause();
      } catch (e) {
        console.log(e);
      }
    },

    onAnswer: function () {
      this.session.answer(this.options);
      this.canCall = false;
    },

    async startCall() {
      if (this.phone_number > 0) {
        this.canCall = false;
        if (await this.testCanCall(this.phone_number)) {
          var sip_num = "sip:" + this.phone_number + "@" + this.server;
          this.session = this.ua.call(sip_num, this.options);
        } else {
          this.$root.$emit("show-info", {
            text: "Вы не можете позвонить на этот номер",
          });
          this.canCall = true;
        }
      }
    },

    async testCanCall(phone) {
      if (this.$root.profile.role < 20) {
        let data;
        console.log("this.$root.api", this.$root.config);
        let response = await this.$axios.get(
          this.$root.config["baseUrl"] + "/auto_offers",
          {
            params: {
              sort: {
                key: "credate",
                order: "DESC",
              },
              filters: {
                phone: phone,
                credate: `${new Date().addDays(-14).date} - ${new Date().date}`,
              },
              pager: { page: 0, count: 0, limit: 1 },
            },
          }
        );
        if (response.data.data[0]) data = response.data.data[0];
        if (
          data &&
          data.manager_id &&
          data.manager_id != this.$root.profile.id &&
          data.active_status == 6
        ) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scope>
.phone {
  white-space: nowrap;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  .form-phone {
    font-size: 16px;
    height: 30px;
    border-radius: 2px;
    border: 1px solid;
    background: #eee;
    padding: 0 5px;
  }
  .phone-el {
    display: inline-block;
    margin: 0 2px;
  }
  .btn {
    display: inline-block;
  }
  .call-btn-start {
    background: #02b302;
  }
  .call-btn-stop {
    background: #dc3545;
  }
  .call-btn {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin: 2px;
    padding: 0;
  }
  .call-btn-i {
    background: #aaa;
  }
}
</style>

<template>
  <a-view-chip
    :value="value"
    :options="model.options"
    :hideText="model.hideText"
    :showText="model.showText"
  />
</template>
 
<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  },
};
</script>
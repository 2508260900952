<template>
  <div>
    <v-card class="mb-2" width="100%" @click="$emit('clickTrumb')">
      <div class="pa-3" height="50px" style="height: 50px">
        <h4>#{{ q.id }} {{ q.t }}</h4>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    trumb: Boolean,
    full: Boolean,
    q: { type: Object, default: () => {} },
  },
};
</script>
<style lang="sass">
.myButton
  color: #fff
  border-radius: 5px
  font-family: Avenir, Helvetica, Arial, sans-serif
  font-size: 1.04em
  letter-spacing: 0.05em
  width: 100%
  transition: 0.3s
  margin-top: .5rem
  display: block
  width: 100%
  color: #fff
  background-color: #818181
  border-color: #818181
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
.myButton:not([disabled]):hover
  opacity: 0.7

.myButton:disabled
  color: #fff
  background-color: rgba(0, 0, 0, 0.26)
  border-color: rgba(0, 0, 0, 0.26)
</style>

<template>
  <select class="f-search" :value="value" @input="$emit('input', $event.target.value)" @change="$emit('change', $event.target.value)">
    <option value="">{{ $t("All") }}</option>
    <option v-for="(el, i) in getSortOptions" :key="i" :value="el.value || el">{{ el.text || el }}</option>
  </select>
</template>

<script>
import modelOptions from "@/components/libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array, Object],
    model: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {
    getSortOptions() {
      let list = JSON.parse(JSON.stringify(this.getFilterOptions(this.model) || []));
      let res = [];
      res = list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
      return res;
    },
  },
};
</script>
<style lang="scss">
@import "styles.scss";
</style>

<template>
  <span v-html="text"></span>
</template>

<script>
export default {
  props: {
    value: [Array, Number, String],
  },
  computed: {
    text() {
      if (this.value) {
        console.log(this.value);
        let v = [...this.value];
        let users = [];
        v.forEach((el) => {
          users.push(el.user_name);
        });
        return users.join(";");
      }
    },
  },
};
</script>

<template>
  <div class="py-1">
    <v-expansion-panels accordion v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header style="font-size: 1.25rem; font-weight: 500; letter-spacing: 0.0125em">
          {{ title }} [{{ items.length }}]
          <v-spacer />
          <div v-if="panel !== undefined && false" class="text-right pa-0">
            <v-btn class="ma-0" @click.native.stop="addItem()" color="primary">Добавить</v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
            <a-table-f-data2
              ref="tableSchedule"
              :dataTable="items"
              :useQuery="false"
              :model="model"
              :searchable="false"
              :defaults="{
                filters: {},
                sort: {},
                paramName: 'work',
              }"
              @click="onClickRow($event)"
            >
              <template v-slot:item.actions="{ row }">
                <div class="d-flex" v-if="(data.status === 1 || row) && accessEdit">
                  <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="remove(row.id)">
                    <v-icon small>fas fa-times</v-icon>
                  </v-btn>
                </div>
              </template>
            </a-table-f-data2>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <s-document-dialogs-detail-edit
      v-if="showEditDialog"
      v-model="showEditDialog"
      :id="idEdit"
      :dataTable="items"
      :readonly="false"
      :RO="fieldsRO"
      :m="m"
      type=""
      @save="items = $event"
      @refresh="fitchData()"
    >
    </s-document-dialogs-detail-edit>
  </div>
</template>

<script>
import { getAccess, popupMenu, autoHeightBlock } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, autoHeightBlock],
  components: {},
  props: {
    id: Number,
    title: { type: String, default: "Спецификация" },
    data: { type: Array, default: () => [] },
    height: { type: Number, default: 300 },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      panel: undefined,
      idEdit: 0,
      fieldsRO: [],
      m: {
        list: [
          { name: "actions", width: 40 },
          { name: "department", title: "Отдел", type: "select", sourceList: "department", sortable: true, width: 100 },
          { name: "name", title: "Наименование", type: "string", sortable: !true, width: 250, style: "white-space: normal;" },
          { name: "work_days", title: "Дней", type: "number", sortable: !true, width: 80, style: "text-align: center;" },
          { name: "work_cost", title: "Стоимость", type: "number", sortable: !true, width: 100 },
        ],
        form: [
          { name: "department", title: "Отдел", type: "select", sourceList: "department" },
          { name: "service_id", title: "Наименование", type: "selectwork", validator: ["req"] },
          { name: "work_days", title: "Дней", type: "number" },
          { name: "work_cost", title: "Стоимость", type: "number" },
        ],
      },
      showEditDialog: false,
    };
  },
  created() {},
  watch: {},
  computed: {
    model() {
      return this.m.list;
    },
    accessEdit() {
      return true;
    },
    items: {
      get() {
        let d;
        try {
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
        //    this.$emit("input", this.isReverse ? v.reverse() : v);
      },
    },
    canAdd() {
      return true;
    },
  },
  methods: {
    onClickRow(e) {
      if (e.field.name == "actions") {
      } else {
        this.idEdit = e.row.id;

        this.showEditDialog = true;
      }
    },
    remove(key) {
      let e = this.items.filter((i) => i.id !== key);
      //      e.splice(key, 1);
      this.items = e;
    },
    addItem() {
      this.idEdit = 0;
      this.showEditDialog = true;
      return;
      let e = this.items;
      e.push({});
      this.items = e;
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>

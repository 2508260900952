/** @format */

import info from './modules/info';
import dirs from './modules/dirs';
import shop from './modules/shop';
import shopLink from './modules/shopLink';
import organizations from './modules/organizations';
import users from './modules/users';
import vendor from './modules/vendor';
import creditOrg from './modules/creditOrg';
import supply from './modules/supply';
import crypt from './modules/crypt';
import payment from './modules/payment';
import deliveryMan from './modules/deliveryMan';
import loanRequest from './modules/loanRequest';
import loan from './modules/loan';

import uploadsGroup from './modules/uploadsGroup';

//cashRemainder
const models = {
  dirs,
  info,
  crypt,
  organizations,
  shop,
  vendor,
  creditOrg,
  users,
  supply,
  payment,
  deliveryMan,
  shopLink,
  uploadsGroup,
  loanRequest,
  loan,
};

export { models };

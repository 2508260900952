<template>
  <v-autocomplete
    outlined
    :clearable="!readonly"
    :disabled="disabled"
    :items="items"
    v-model="value_access"
    :label="model.title"
    :dense="config.dense || false"
    :readonly="readonly"
    item-text="text"
    item-value="value"
    small-chips
    multiple
    deletable-chips
    hide-selected
  />
</template>

<script>
import modelOptions from "../../libs/modelOptions";
export default {
  mixins: [modelOptions],
  props: {
    value: [Array, Number, Object],
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    model: Object,
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    value_access: {
      get() {
        let res =
          this.value.map((e) => {
            if (this.model.accessType == "user") return e.user_id;
            if (this.model.accessType == "role") return e.role;
            return null;
          }) || [];
        return res;
      },
      set(v) {
        let res;
        if (this.model.accessType == "role")
          res = [
            ...v.map((e) => {
              return { role: e };
            }),
          ];
        if (this.model.accessType == "user")
          res = [
            ...v.map((e) => {
              return { user_id: e };
            }),
          ];
        this.$emit("input", res);
      },
    },

    items() {
      let res = [];
      if (this.model.accessType == "role") res = this.$root.dirs.roles;
      if (this.model.accessType == "user") res = this.$root.dirs.user;
      return res;
    },

    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss"></style>

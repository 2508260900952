<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-data2
        :ref="'table-statusHistory'"
        :dataTable="data"
        :useQuery="false"
        :model="model"
        :searchable="false"
        :defaults="{
          filters: {},
          sort: { key: 'id', order: 'DESC' },
          paramName: 'statusHistory',
        }"
        @click="onClickRow($event, t)"
      />
    </v-card-text>
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  components: {},
  props: {
    height: { type: Number, default: 300 },
    data: { type: Array, default: () => [] },
    m: Object,
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    model() {
      let model = [];
      console.log("getModel history", this.m.statuses);
      if (this.m)
        model = [
          { name: "createdon", title: "Дата", type: "datetime", width: 120 },
          { name: "createdby_id", title: "Автор", type: "select", dir: "user", width: 220 },
          {
            name: "value",
            title: "Значение",
            type: "select",
            options: this.m?.statuses || [],
            width: 120,
          },
        ];
      return model;
    },
  },
  methods: {},
};
</script>

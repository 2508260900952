/** @format 
 *   "name": "test vendor",
      "address": null,
      "phone": null,
      "whatsapp": null,
      "category": null,
      "id": 1,
      "createdon": "2024-03-27T08:02:26.000Z",
      "createdby_id": 1,
      "updatedon": null,
      "updatedby_id": null,
      "user_id": null,
      "organization_id": 4,
      "status": 1
*/

const vendor = {
  title: 'Поставшики',
  routeName: 'vendor_view',
  accessName: 'vendor',
  api: { default: '/mko/vendor', 200: '/mko/credit_org/vendors' },

  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 250 },
    { name: 'address_fact', title: 'Адрес', type: 'address', sortable: true, width: 250 },
    { name: 'phone', title: 'Телефон', type: 'string', sortable: true, width: 120 },
    { name: 'whatsapp', title: 'whatsapp', type: 'string', sortable: true, width: 120 },
    { name: 'category', title: 'Категория', type: 'string', sortable: true, width: 120 },
    { name: 'organization_id', title: 'Организация', type: 'select', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 120 },
  ],
  list_role100: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 250 },
    { name: 'address_fact', title: 'Адрес', type: 'address', sortable: true, width: 250 },
    { name: 'phone', title: 'Телефон', type: 'string', sortable: true, width: 120 },
    { name: 'whatsapp', title: 'whatsapp', type: 'string', sortable: true, width: 120 },
  ],
  list_role200: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 250 },
    { name: 'address_fact', title: 'Адрес', type: 'address', sortable: true, width: 250 },
    { name: 'category', title: 'Категория', type: 'string', sortable: true, width: 120 },
    { name: 'shops_count', title: 'Магазинов', type: 'number', sortable: true, width: 120 },
    { name: 'payments', title: 'Сумма выплат', type: 'number', sortable: true, width: 120 },
    { name: 'reiting', title: 'Средний КРМ', type: 'number', sortable: true, width: 120 },
  ],
  form: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'], autofocus: true },
    { name: 'address_fact', title: 'Адрес фактический', type: 'address' },
    {
      name: 'address_yur',
      title: 'Адрес юридический',
      type: 'address',
      validator: ['req'],
      config: { clone: { field: 'address_fact', title: 'Соовпадает с фактическим' } },
    },
    { name: 'name_director', title: 'Руководитель', type: 'fioData', validator: ['req'] },
    { name: 'name_manager', title: 'Контактное лицо', type: 'fioData', validator: ['req'] },
    { name: 'phone', title: 'Телефон', type: 'phone', validator: ['req', 'tel'], mask: '996*********' },
    { name: 'whatsapp', title: 'whatsapp', type: 'phone', validator: ['req', 'tel'], mask: '996*********' },
    { name: 'category', title: 'Категория', type: 'string' },
    { name: 'type_of_ownership', title: 'Форма собственности', type: 'select', options: 'typeOwnership', validator: [] },
    { name: 'data.inn', title: 'ИНН', type: 'string', validator: ['req'] },
    { name: 'data.okpo', title: 'ОКПО', type: 'string', validator: ['req'] },
    { name: 'data.bank_name', title: 'Наименование банка', type: 'string', validator: ['req'] },
    { name: 'data.bank_address', title: 'Адрес банка', type: 'address', validator: ['req'] },
    { name: 'data.bank_account', title: 'Расчетный счет', type: 'string', validator: ['req'] },
    { name: 'data.bik', title: 'БИК', type: 'string', validator: ['req'] },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: '/users/organization/select', validator: [] },
    { name: 'user_id', title: 'Пользователь', type: 'selectapi', api: '/users/users/select', validator: [], roleShow: [1000] },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 0, validator: ['req'], roleShow: [1000] },
    { name: 'data' },
  ],
  viewForm1: {
    fields: 'name,category,address_fact,name_manager,phone',
  },
  viewForm2: {
    fields: 'type_of_ownership,data.inn,data.okpo,data.bank_name,data.bank_address,data.bank_account,data.bik',
  },
  config: {
    watch: 'type_of_ownership',
    default: {
      title: 'Поставщик',
      fields: 'name,address_fact,phone,whatsapp,category,organization_id,status,user_id,type_of_ownership',
      fieldsRO: '',
      fieldsForm: 'name,address_fact,type_of_ownership,phone#6,whatsapp#6,category,organization_id#6,user_id#6,status',
    },
    1: {
      title: 'Поставщик (ИП)',
      fields: `name,address_fact,address_yur,phone,whatsapp,name_director,name_manager,
        data.inn,data.okpo,data.bank_name,data.bank_address,data.bank_account,data.bik,
        organization_id,status,user_id,type_of_ownership`,
      fieldsRO: '',
      fieldsForm: `name,address_fact,type_of_ownership,address_yur,
        data.inn#6,data.okpo#6,data.bank_name,data.bank_address,data.bank_account#6,data.bik#6,
        name_director,name_manager,
        phone#6,whatsapp#6,user_id#6,status#6`,
    },
    2: {
      title: 'Поставщик (ОсОО)',
      fields: `name,address_fact,address_yur,phone,whatsapp,images,
        data.inn,data.okpo,data.bank_name,data.bank_address,data.bank_account,data.bik,
        organization_id,status,user_id,type_of_ownership`,
      fieldsRO: '',
      fieldsForm: `name,address_fact,type_of_ownership,address_yur,
        data.inn#6,data.okpo#6,data.bank_name,data.bank_address,data.bank_account#6,data.bik#6,
        name_director,name_manager,
        phone#6,whatsapp#6,user_id#6,status#6`,
    },
    role_200: {
      api: '/mko/credit_org/vendors',
    },
  },
};
export default vendor;

<template>
  <v-dialog ref="dialog" v-model="showPicker" persistent width="290px" :disabled="readonly">
    <template v-slot:activator="{ on, attrs }">
      <slot>
        <div v-bind="attrs" v-on="on">dddddddddddddd</div>
      </slot>
    </template>
    <div style="text-align: left">
      <v-row v-for="(el, i) in list" :key="i" no-gutters class="mb-0">
        <v-col cols="0" class="flex-grow-1 flex-shrink-0">
          <Item
            :value="list[i]"
            :config="{ dense: true, hideDetails: true }"
            :readonly="![-2, i].includes(changed) || readonly"
            @commit="commit($event, i)"
            @change="changed = i"
          />
        </v-col>
        <v-col v-if="[-2, i].includes(changed)" cols="1" class="flex-grow-0 flex-shrink-1">
          <div class="pt-2">
            <v-btn elevation="0" fab small color="red" dark bottom @click="onClickDel(i)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <Item v-if="changed == -1" :value="newData" :config="{ dense: true, readonly, hideDetails: 'auto' }" @commit="commit($event)" />

      <div class="text-left mt-1">
        <v-btn v-if="changed == -2" elevation="2" fab x-small dark color="green" top left @click="onClickAdd">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  components: {
    Item: () => import("./BuyingRulesItem"),
  },
  props: {
    value: String,
    text: { type: String, default: "Не указано" },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      changed: -2,
      isChanged: false,
      d: this.value || "",
      showPicker: false,
    };
  },

  computed: {
    list: {
      set(v) {
        console.log("set list", v);
        this.$emit("input", v);
      },
      get() {
        let res = [];
        if (this.value) res = this.value;
        if (!Array.isArray(res)) res = [];
        return res;
      },
    },
  },
  methods: {
    onValid(e) {
      console.log(e);
      this.validate($event);
    },

    async reset() {},
    onChange(i) {
      console.log("onchange", i);
      this.changed = i;
    },
    commit(e, i = -1) {
      let d = [...this.list];
      console.log("commit", d, i);
      if (i == -1) {
        d = [...d, e];
      } else d[i] = e;
      this.changed = -2;
      this.list = d;
      //this.$emit("input", d);
    },
    onClickDel(key) {
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
    },
    onClickAdd() {
      this.newData = null;
      this.changed = -1;
    },
  },
};
</script>

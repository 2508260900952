<template>
  <div :style="style">
    <div @click="onClick()">
      <span ref="element" v-html="v"></span>
    </div>
    <popup-modal v-if="style.length" ref="popup">
      <template>
        <v-btn style="right: 12px; top: -12px; float: left" fab dark small color="blue-grey" title="Закрыть окно" @click="onClose()">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <span @click1="onClose()" v-html="v"></span>
      </template>
    </popup-modal>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
  data: function () {
    return {
      height: 0,
      style: "",
    };
  },
  created() {
    if (this.value) {
      this.$nextTick(() => (this.height = this.$refs.element.offsetHeight));
    }
  },
  computed: {
    v() {
      if (this.value && typeof this.value === "string") return this.value.replace(/(\r\n|\n|\r)/g, "<br/>");
      return this.value;
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.$nextTick(() => (this.height = this.$refs.element.offsetHeight));
      }
    },
    height(v) {
      this.style = v > 120 ? "height:120px; overflow-y: auto; border-bottom-color: yellow; border-bottom-width: 4px; border-bottom-style: dotted;" : "";
    },
  },
  methods: {
    onClose() {
      this.$refs.popup.close();
    },
    onClick() {
      if (this.style.length) {
        this.$refs.popup.open();
      }
    },
  },
};
</script>

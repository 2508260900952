<template>
  <v-combobox
    outlined
    clearable
    :items="data"
    :value="value"
    :label="$t(model.title)"
    :error-messages="error"
    :disabled="disabled"
    :hide-details="config.hideDetails || false"
    :dense="config.dense || false"
    @focus="fetchData()"
    @input="$emit('input', $event)"
  >
  </v-combobox>
</template>

<script>
export default {
  props: {
    value: [String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
    };
  },
  // created() {
  //   this.fetchData();
  // },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("config/fitchSource", this.model.name);
      this.loading = false;
      this.data = this.$store.getters["config/getSource"];
    },
    onChange(e) {
      console.log(e);
    },
  },
};
</script>
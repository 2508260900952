<template>
  <v-row style="margin: 0; justify-content: center">
    <v-icon v-if="icon" style="font-size: 16px">{{ icon }}</v-icon>
    <span class="d-none d-sm-flex pl-2"><slot></slot></span>
  </v-row>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>
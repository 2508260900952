<template>
  <div>
    <v-text-field
      v-if="mode == 'edit'"
      :value="value"
      @input="$emit('input', $event)"
      dense
      hide-details
      class="no-underline"
      type="number"
      @blur="mode = 'view'"
      autofocus
    ></v-text-field>
    <span v-else @click="onClick()">{{ val }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: { type: Object, default: () => {} },
  },
  data() {
    return {
      mode: "view",
    };
  },
  computed: {
    val() {
      let v = this.value;
      if (v || v === 0) {
        if (parseFloat(v)) v = parseFloat(v);
        return v.toLocaleString("ru-Ru");
      }
      return this.value;
    },
  },
  methods: {
    onClick() {
      console.log("clic");
      if (this.model.edit) this.mode = "edit";
    },
  },
};
</script>

<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title> Список загрузок </template>
      <v-card-text>
        <div>
          <v-row>
            <v-simple-table style="width: 100%">
              <tbody>
                <tr v-for="(el, key) in tasks" :key="key">
                  <td class="text-left">
                    {{ el.id }}
                  </td>
                  <td class="text-left">
                    {{ el.status }}
                  </td>
                  <td class="text-left">
                    {{ el.task.file.name }}
                  </td>
                  <td class="text-left">{{ getSize(el.size) }}Mb</td>
                  <td class="text-left">{{ getSize(el.loaded) }}Mb</td>
                  <td class="text-left">{{ progress(el) }}%</td>
                  <td width="10px">
                    <div class="d-flex">
                      <v-btn v-if="el.status == 1" class="mx-2" fab small title="Запустить" @click.prevent="$emit('start', key)">
                        <v-icon> fas fa-play </v-icon>
                      </v-btn>
                      <v-btn v-else-if="el.status == 2" class="mx-2" fab small title="Пауза" @click.prevent="$emit('pause', key)">
                        <v-icon> fas fa-pause </v-icon>
                      </v-btn>
                      <v-btn v-else-if="el.status == 3" class="mx-2" fab small title="Готово">
                        <v-icon> fas fa-check </v-icon>
                      </v-btn>
                      <v-btn v-else class="mx-2" fab small title="Ошибка" @click.prevent="$emit('start', el.id)">
                        <v-icon> fas fa-bug </v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-row>
        </div>
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    tasks: Array,
  },
  methods: {
    progress(e) {
      return e.size ? ((e.loaded / e.size) * 100).toFixed(2) : 0;
    },
    getSize(s) {
      return (s / (1024 * 1024)).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-menu bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <span v-if="!$vuetify.breakpoint.xsOnly">
          {{ $root.profile.name }}
        </span>
        <v-btn icon large v-on="on">
          <v-avatar size="32">
            <v-img :src="getImage()"></v-img>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <div class="pt-2 mx-auto text-center">
          <v-avatar size="96" @click="showChangeAvatarDialog = true">
            <v-img :src="getImage(96)"></v-img>
          </v-avatar>
          <h4 class="pt-2">{{ $root.profile.name }}</h4>
          <v-list-item v-if="!true && $vuetify.breakpoint.xsOnly">
            {{ $root.profile.name }}
          </v-list-item>
          <v-list-item v-if="!true" link @click="hardReload">
            <v-list-item-icon>
              <i class="fas fa-sync-alt"></i>
            </v-list-item-icon>
            <v-list-item-title> Обновить </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$root.logout">
            <v-list-item-icon>
              <i class="fas fa-sign-out-alt"></i>
            </v-list-item-icon>
            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item>
        </div>
      </v-card>
    </v-menu>
    <change-avatar v-model="showChangeAvatarDialog" />
  </div>
</template>

<script>
import changeAvatar from "./changeAvatar.vue";
export default {
  components: { changeAvatar },
  data() {
    return {
      showChangeAvatarDialog: false,
    };
  },
  created() {},
  methods: {
    hardReload() {
      window.location.reload(true);
    },
    getImage(n = 32) {
      let im = (this.$store.getters["auth/profile"]?.avatar || [null])[0];
      let path;
      if (im) {
        path = this.$root.config.baseUrl + "/image/download/" + im + `?width=${n}&height=${n}`;
      } else {
        path = "/img/man.png";
      }
      return path;
    },
  },
};
</script>

<template>
  <div style="text-align: left">
    <TreeBlock
      class="pb-2"
      @change="onChange($event)"
      @copy="onCopy($event, index)"
      @rename="onRename($event)"
      @remove="onRemove($event)"
      @create="onCreate($event)"
      :value="data"
    />
    <v-textarea
      outlined
      v-model="dataText"
      :label="model.title"
      :error-messages="error"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :disabled="disabled"
      :dense="model.opts && model.opts.includes('dense')"
      :auto-grow="model.opts && model.opts.includes('auto-grow')"
      :placeholder="model.placeholder"
      :persistent-placeholder="!!model.placeholder || false"
      :rows="model.rows || 5"
      @input1="$emit('input', $event)"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  components: {
    TreeBlock: () => import("@/views/tree/treeBlock"),
  },
  props: {
    value: [String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(v) {
        let res = v;
        try {
        } catch (error) {}

        this.$emit("input", res);
      },
    },
    dataText: {
      get() {
        return JSON.stringify(this.value);
      },
      set(v) {
        let res = v;
        try {
          console.log("try convert json");
          res = JSON.parse(v);
        } catch (error) {
          try {
            console.log("error", error);
            console.log("try eval");
            res = eval("(" + v + ")");
          } catch (error) {
            console.log("error", error);
          }
        }

        this.$emit("input", res);
      },
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {
    onCopy(e) {
      console.log("onCopy:", e);
      //this.data[e.i] = e.v;
    },
    onRename(e) {
      console.log("onRename:", e);
      //this.data[e.i] = e.v;
    },
    onChange(e) {
      console.log("onChange:", e);
      if (e.i == -1) {
        this.data = e.v;
        return;
      }
      this.data[e.i] = e.v;
    },
    onCreate(e) {
      console.log("onCreate:", e);
      this.data[e.i] = e.v;

      // this.data[e.i] = e.v;
    },
    onRemove(e) {
      console.log("onRemove:", e);
      this.data[e.i] = e.v;
    },
  },
};
</script>

<template>
  <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth="'300px'">
    <v-avatar size="128" class="">
      <v-img :src="getImage()"></v-img>
    </v-avatar>
    <v-file-input accept="image/*" outlined dense :label="model.title" type="file" @change="onChange" class="mt-4" />
    <template v-slot:actions>
      <a-btn-save @click="submit()" title="Сохранить" />
      <v-spacer />
      <v-btn @click="$emit('input')">Закрыть</v-btn>
    </template>
  </dialog-edit2>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  components: {},
  mixins: [getAccess],
  props: {
    value: Boolean,
    title: String,
  },
  data() {
    return {
      api: "/signup/set_profile",
      model: { name: "avatar", type: "avatar", title: "Выбрать изображение" },
      removeDialogShow: false,
      configDialpgShow: false,
      loaded: false,
      avatar: [],
    };
  },
  computed: {
    text() {
      return "";
    },
    ava() {
      return this.$store.getters["auth/profile"]?.avatar || [];
    },
  },
  created() {
    this.avatar = this.$store.getters["auth/profile"]?.avatar || [];
  },
  methods: {
    async submit() {
      try {
        let response = await this.$axios.post(this.api, { avatar: this.avatar });
        this.loading = false;
        let status = response.data.status == "ok";
        if (response.data.data) {
          this.$root.$emit("show-info", {
            text: "Данные записаны",
          });
          this.$emit("input");
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },
    getImage() {
      let im = (this.avatar || [null])[0];
      let path;

      if (im) {
        path = this.$root.config.baseUrl + "/image/download/" + im + "?width=128&height=128";
      } else {
        path = "/img/man.png";
      }
      return path;
    },
    onChange(file) {
      if (file) this.uploadFile(file);
    },
    uploadFile(file) {
      this.loading = true;
      this.status = 0;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      this.$axios
        .post("/image/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.status = parseInt((progressEvent.loaded / progressEvent.total) * 100);
          },
        })
        .then((response) => {
          this.loading = false;
          let data = response.data.data.file;
          this.avatar = [data, ...this.ava];
          console.log("ok ", data);
        })
        .catch(function (error) {
          this.$root.$emit("show-info", {
            text: "Error: " + error,
          });
        });
    },
  },
};
</script>

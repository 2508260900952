<template>
  <div>
    <v-simple-table :dense="config.dense || false">
      <tbody>
        <tr v-for="(el, i) in ['Create:', { field: 'createdby_id' }, ' ', { field: 'createdon' }]" :key="i">
          <span v-if="typeof el == 'string'" class="text-left" style="font-weight: 500; height: 28px"> {{ el }}: </span>
          <td class="text-left px-0" style="height: 38px">
            <span v-if="el.preText">{{ el.preText }}</span>
            <component v-if="el.type" :is="'a-view-' + el.type" :model="el" :values="data" :value="el.value || data[el.name]" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    model1: Array,
    config: {
      type: Object,
      default: { dense: false, hideDetails: false, hideNull: false },
    },
    cols: { type: Number, default: 1 },
  },
  data() {
    return {
      m: [],
      model: [
        { name: "createdby_id", title: "Автор", type: "select", dir: "user" },
        { name: "createdon", title: "создан", type: "datetime" },
        { name: "updatedby_id", title: "Изменил", type: "select", dir: "user" },
        { name: "updatedon", title: "изменен", type: "datetime" },
      ],
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
    },
  },
  created() {},
  watch: {},
  methods: {
    field(name) {
      return this.model.find((el) => el.name == name) || {};
    },
  },
};
</script>

<template>
  <div :class="getClass" :style="style">
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      badge: false,
    };
  },
  computed: {
    color() {
      if (!this.model?.textColor) return "";
      //this.badge = true;
      return this.model?.textColor(this.values);
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },

    getClass() {
      let classStr = "";
      if (this.model?.class) classStr = this.model?.class;
      if (this.color) classStr += " red--text";
      return classStr;
    },
  },
};
</script>

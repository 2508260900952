<template>
  <span :style="style">
    <span v-if="model.preText && value">
      {{ model.preText }}
    </span>
    {{ text || value }}
  </span>
</template>

<script>
import { modelOptions } from "@/components/mixings";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: [Object],
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: [],
      text: null,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
  watch: {
    value(v) {
      if (v) this.fetchData();
      else this.text = "";
    },
  },
  methods: {
    async fetchData() {
      if (!this.value) {
        return;
      }
      let api = this.model.api;
      let type = "get";
      let d = {};
      if (typeof api === "object") {
        type = api.type || type;
        if (api.data) {
          d.name = api.data?.name;
          d.params = {};
          for (const key in api.data.params) {
            if (typeof api.data.params[key] === "string" && api.data.params[key].includes("function(d)")) {
              let func = eval(api.data.params[key]);
              d.params[key] = func(this.values) || 0;
            } else d.params[key] = api.data.params[key];
          }
        }

        //data = api.data || null;
        api = api.api;
      }
      let t = this.model?.itemField?.text || "text";
      let v = this.model?.itemField?.value || "value";
      let filters = {};
      if (this.model?.itemField) {
        filters = { [v]: this.value };
      } else {
        filters = { id: this.value };
      }
      let params = { filters };

      this.loading = true;
      let response = type == "get" ? await this.$axios.get("/" + api, { params }) : await this.$axios.post(api, d);
      //let response = await this.$axios.get("/" + this.model.api, { params: { filters } });
      this.loading = false;
      this.data = response.data.data;
      this.text = this.data[0]?.[t];
    },
  },
};
</script>

<template>
  <div class="d-flex" style="position: relative">
    <v-combobox
      outlined
      :clearable="!readonly"
      :readonly="readonly"
      hide-selected
      :items="getOptions(model)"
      :value="value"
      :label="model.title"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      @input="$emit('input', $event)"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
    >
      <template v-slot:selection="data" v-if="model.chips || false">
        <v-chip v-bind="data.attrs" :input-value="data.selected" close small @click:close="data.parent.selectItem(data.item.value)" class="mt-1">
          {{ $t(data.item.text) }}
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";
export default {
  mixins: [modelOptions],
  props: {
    value: [Array, String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
    };
  },
  // created() {
  //   this.fetchData();
  // },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {
    onChange(e) {
      console.log(e);
    },
  },
};
</script>

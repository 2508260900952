<template>
  <v-autocomplete
    outlined
    clearable
    :items="data"
    item-text="text"
    item-value="value"
    :value="value"
    :label="$t(model.title)"
    :error-messages="error"
    :disabled="disabled"
    @change="updateData($event)"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    value: [String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let response = await this.$axios.get(this.model.api);
      this.loading = false;
      this.data = response.data.data;
    },
    async updateData(e) {
      this.loading = true;
    //   let response = await this.$axios.get(this.model.api);
      this.loading = false;
      this.$emit("input", e);
    },
  },
};
</script>
<template>
  <div class="py-1">
    <v-expansion-panels accordion v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header style="font-size: 1.25rem; font-weight: 500; letter-spacing: 0.0125em">
          {{ model.title }} [{{ items.length }}]
          <v-spacer />

          <div v-if="panel !== undefined" class="text-right pa-1">
            <v-btn v-if="model.photo !== false && !readonly" class="text-right ma-1" @click.native.stop="addImage()" color="primary">+ Фото</v-btn>
            <v-btn v-if="model.video !== false && !readonly" class="ma-1" @click.native.stop="addVideo()" color="primary">+ Видео</v-btn>
            <v-btn class="ma-1" @click.native.stop="showText = !showText" color="primary">
              <v-icon>mdi-text</v-icon>
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mb-2">
          <v-row style="max-height: 300px; overflow: auto">
            <div
              v-for="(el, key) in images"
              :key="key"
              height="150px"
              width="150px"
              class="pa-2 ma-2"
              @dragover="onDragOver(el, key, $event)"
              @dragend="finishDrag(el, key, $event)"
              @dragstart="startDrag(el, key, $event)"
              @dragleave="onDragLeave(el, key, $event)"
              :class="{ over: el === over.item && el !== dragFrom, [over.dir]: el === over.item && el !== dragFrom }"
              style="position: relative"
            >
              <a target="_blank" :href="el.data.includes('video#') ? $root.config.videoUrl + el.data.split('#')[1] : $root.config.imageUrl + el.data">
                <v-img v-if="el.data.includes('video#')" :src="$root.config.imageUrl + el.split('#')[1] + '?width=150&height=150'" width="184px">
                  <s-video-icon :id="el.split('#')[2]" />
                </v-img>
                <v-img v-else aspect-ratio="1" :src="$root.config.imageUrl + el.data + '?width=150&height=150'" width="184px" />
                <v-btn
                  v-if="!readonly"
                  class="mx-0"
                  fab
                  dark
                  x-small
                  color="error"
                  style="position: absolute; right: 10px; top: 10px"
                  title="Удалить"
                  @click.prevent="remove(key)"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </a>
              <v-textarea
                v-if="showText"
                class="pt-2"
                outlined
                :value="el.description"
                label="Описание"
                @input="onChange(key, 'description', $event)"
                :hide-details="config.hideDetails || false"
                :readonly="readonly"
                :disabled="disabled"
                :rows="1"
                auto-grow
              />
            </div>
          </v-row>
          <div class="v-text-field__details" v-if="showError">
            <div class="v-messages theme--light" role="alert">
              <div class="v-messages__wrapper error--text">{{ err }}</div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="dialogFile" hide-overlay persistent max-width="600">
      <v-card class="">
        <v-card-title>Загрузка изображений</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input accept="image/*" multiple outlined chips label="Выберите файл изображений" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">
            Загрузить <template v-slot:loader> <v-progress-linear v-model="progressLoading" color="green" height="35" rounded></v-progress-linear> </template>
          </v-btn>
          <v-btn @click="dialogFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVideoFile" hide-overlay persistent max-width="600">
      <v-card class="">
        <v-card-title>Загрузка видео</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input accept="video/*" multiple outlined chips label="Выберите файл изображений" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">
            Загрузить
            <template v-slot:loader>
              <v-progress-linear v-model="progressLoading" color="green" height="35" rounded></v-progress-linear>
            </template>
          </v-btn>
          <v-btn @click="dialogVideoFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: () => {
        return { hideDetails: true };
      },
    },
  },
  data() {
    return {
      showText: false,
      panel: undefined,
      uploadsFiles: null,
      dialogVideoFile: false,
      dialogFile: false,
      fileLoading: false,
      showError: false,
      err: null,
      over: {},
      startLoc: 0,
      progressLoading: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    isReverse() {
      return this.model?.reverce || false;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    images: {
      get() {
        let i = JSON.parse(JSON.stringify(this.items));
        return this.isReverse ? i.reverse() : i;
      },
      set(i) {
        console.log("set images");
        // let i = JSON.parse(JSON.stringify(v));
        this.items = this.isReverse ? i.reverse() : i;
      },
    },
    items: {
      get() {
        let d = [];
        try {
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        console.log("emit", v);
        this.$emit("input", v);
      },
    },
  },
  methods: {
    startDrag(item, i, e) {
      e.dataTransfer.setData("text", item);
      e.dataTransfer.setData("from", JSON.stringify(this.model));
      e.dataTransfer.effectAllowed = "move";
      // this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = this.model.name;
    },
    finishDrag(item, pos) {
      if (this.readonly) return;
      let el = this.images;
      el.splice(pos, 1);
      el.splice(this.over.pos, 0, item);
      this.images = el;
      setTimeout(() => {
        this.over = {};
      }, 50);
    },

    onDragOver(item, pos, e) {
      if (this.over.pos == pos) return;
      if (this.dragFrom != this.model.name) return;
      if (this.readonly) return;
      e.preventDefault();
      let dir;
      // dir = this.startLoc < e.clientY ? "down" : "up";
      this.over = { item, pos, dir };
      //      setTimeout(() => {        this.over = { item, pos, dir };      }, 50);
    },
    onDragLeave(item, pos, e) {
      if (this.over.pos == pos) this.over = {};
    },
    onChange(key, el, v) {
      let e = this.images;
      e[key][el] = v;
      this.images = e;
    },

    remove(key) {
      let e = this.images;
      e.splice(key, 1);
      this.images = e;
    },
    addImage() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    addVideo() {
      this.uploadsFiles = null;
      this.dialogVideoFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.dialogFile = false;
      this.dialogVideoFile = false;
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.progressLoading = 0;
        let count = this.dialogVideoFile ? this.uploadsFiles.length : 1;
        let api = this.dialogVideoFile ? "/file/upload/video" : "/image/upload";
        this.$axios
          .post(api, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              this.progressLoading = parseInt((progressEvent.loaded / progressEvent.total) * 100);
            },
          })
          .then((response) => {
            let data = response.data.data.file;
            if (this.dialogVideoFile) data = "video#" + data + "#" + (response.data.data?.id || 0);
            let el = this.images;
            if (!Array.isArray(el)) el = [];
            if (this.isReverse) {
              el.unshift({ data, description: null });
            } else {
              el.push({ data, description: null });
            }
            this.images = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            this.$root.$emit("show-info", { text: "Error: " + error });
            reject(error);
          });
      });
    },
  },
};
</script>
<style lang="scss">
.over {
  opacity: 0.6;
}
</style>

<template>
  <a-input :value="date" :label="model.title" :config="config">
    <template v-slot:default>
      <v-icon>mdi-calendar</v-icon>
      <div class="d-inline-block">
        <a-input-base-date-picker v-model="date" :readonly="readonly" :text="text" />
      </div>
    </template>
  </a-input>
</template>

<script>
export default {
  props: {
    value: String,
    model: Object,
    config: { type: Object, default: {} },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: "Не указано",
    };
  },

  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    date: {
      get: function () {
        if (!this.value) return new Date().date;
        this.text = new Date(this.value).date;
        return new Date(this.value).date;
      },
      set: function (v) {
        this.$emit("input", v);
        this.$emit("commit", v);
      },
    },
  },
};
</script>

<template>
  <a-dialog-window :value="value" @input="$emit('input')">
    {{ title || "Вы действительно хотите удалить этот элемент?" }}
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="
          $emit('input');
          $emit('remove');
        "
        color="error"
        >Удалить</v-btn
      >
      <v-btn @click="$emit('input')">Отмена</v-btn>
      <v-spacer></v-spacer>
    </template>
  </a-dialog-window>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
  },
  computed: {
    text() {
      return "";
    },
  },
};
</script>
<template>
  <a-input-base-date-picker-range
    :value="v"
    @input="onInput($event)"
    @change="$emit('change', $event)"
  >
    <div class="f-search">{{ v }}</div>
  </a-input-base-date-picker-range>
</template>

<script>
export default {
  props: {
    value: [Number, String, Array],
  },
  computed: {
    v() {
      if (typeof this.value === "string" || typeof this.value === "number") {
        return this.value;
      }
      if (Array.isArray(this.value)) {
        let from = this.value[0].value.split(" ");
        let to = this.value[1].value.split(" ");
        if (from[0] && to[0]) {
          let r = from[0] + " - " + to[0];
          return r;
        }
      }
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
    },
  },
};
</script>
<style lang="scss">
@import "styles.scss";
</style>
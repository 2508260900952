<template>
  <a-table-f-base
    ref="base"
    :model="model"
    :data="data"
    :loading="loading"
    :sort="sort"
    :filters="filters"
    :searchable="searchable"
    :selectedRows="selectedRows"
    :editItem="editItem"
    :footerHide="footerHide"
    :paramName="defaults.paramName || ''"
    @click="onClickRow($event)"
    @dblclick="onDblClickRow($event)"
    @sort="onSort($event)"
    @change-filter="onInputFilter($event)"
    @scrollEnd="onScrollEnd($event)"
    @checkedAll="setCheckAll($event)"
  >
    <template v-slot:top>
      <slot name="top" />
    </template>
    <template v-for="el in model" v-slot:[getSlotName(el)]="{ item, row, field }">
      <slot :name="getSlotName(el)" :item="item" :row="row" :field="field" />
    </template>
    <template no-data>
      <slot name="no-data" />
    </template>
    <template v-slot:footer>
      <slot name="footer">
        <div class="f-table-footer" v-if="footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-for="(el, key) in footer" :key="key">{{ key }}: {{ el }}</div>
          </div>
        </div>
      </slot>
    </template>
  </a-table-f-base>
</template>

<script>
import { getIndex } from "../../../mixings";
import lodash from "lodash";

export default {
  mixins: [getIndex],
  props: {
    apiList: Array,
    model: [Object, Array],
    useQuery: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    selectedRows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    defaults: {
      type: Object,
      default: () => {
        return {};
      },
    },
    footer: {
      type: Object,
      default: () => {},
    },
    footerHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataTable: [],
      urlPrefix: "q",
      editItem: {},
      data: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.init();
  },
  methods: {
    getSlotName(model) {
      return `item.` + model.name;
    },
    setCheckAll(v) {
      for (const el of this.data) {
        if (el?.check_disable !== 1) el.checked = v;
      }
    },
    onClickRow(event) {
      if (event.field.type === "folder" && event.row.isparent) {
        this.filters.parent_id = event.row.id;
        this.onChangeFilter();
      }
      if (event.field?.typeEdit) {
        this.editItem = {
          row: event.row,
          field: event.field.name,
          key: event.key,
        };
      }
      this.$emit("click", event);
    },
    init() {
      this.date = [];
      if (this.defaults.filters) this.filters = this.defaults.filters;
      else this.filters = {};
      if (this.defaults.sort) this.sort = this.defaults.sort;
      else this.sort = { key: "id", order: "ASC" };
      this.reloadData();
    },
    reloadData() {
      this.updateData();
    },
    async updateData() {
      this.data = [];
      for (let i = 0; i < this.apiList.length; i++) {
        const api = this.apiList[i];
        this.api = api;
        await this.fetchData(true);
      }
    },
    filterData(key, v, type, data) {
      let val = v;
      if (type == "LIKE") val = val.replaceAll("%", "");

      return data.filter(d => {
        let field = d[key];
        if (!isNaN(Date.parse(field))) {
          if (typeof val === "string") val = new Date(val.split(" ")[0] + " 00:00:00.000");
          field = new Date(field);
        }
        if (type == ">=") return field >= val;
        if (type == "<=") return field <= val;
        if (type == "LIKE") return field.includes(val);
        return true;
      });
    },

    onSort: function (el) {
      if (this.sort.key == el) {
        if (this.sort.order == "ASC") {
          this.sort.order = "DESC";
        } else {
          this.sort.order = "ASC";
        }
      } else {
        this.sort.key = el;
        this.sort.order = "ASC";
      }
      if (this.useQuery) {
        this.updateUrlParams();
      } else {
        this.updateData();
      }
    },
    updateUrlParams: function () {
      var params = {
        sort: this.sort,
        filters: this.filters,
      };
      this.$router.push({
        query: { [this.urlPrefix]: JSON.stringify(params) },
      });
    },
    onInputFilter: lodash.debounce(function () {
      this.onChangeFilter();
    }, 500),
    onChangeFilter: function () {
      if (this.useQuery) {
        this.updateUrlParams();
      } else {
        this.updateData();
      }
      this.$emit("change-filter", this.filters);
    },
    onScrollEnd(e) {},
  },
};
</script>

<template>
  <v-flex d-flex mx-1 py-1 sm-pb-0 xs12 :id="`message-${item.id}`" :justify-end="item.user_id == user_id" :justify-start="item.user_id != user_id">
    <v-card
      @click.left="$emit('click', $event)"
      :color="item.user_id == user_id ? 'primary lighten-4' : 'secondary lighten-4'"
      :class="{
        'ml-15': item.user_id == user_id && !$vuetify.breakpoint.xsAndDown,
        'mr-15': item.user_id != user_id && !$vuetify.breakpoint.xsAndDown,
        'ml-2': item.user_id == user_id && $vuetify.breakpoint.xsAndDown,
        'mr-2': item.user_id != user_id && $vuetify.breakpoint.xsAndDown,
      }"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="mb-2" :class="{ 'font-weight-bold': item.viewedon == null && item.user_id != user_id }">{{ item.content }}<br /><br /></div>

          <v-list-item-subtitle>
            <div class="d-flex">{{ messageDate }}<v-spacer /> {{ item.user_name }}</div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-flex>
</template>

<script>
import { components } from "@/components/mixings";

export default {
  mixins: [components],
  components: {},
  props: {
    user_id: Number,
    id: Number,
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},

  computed: {
    messageDate() {
      const d = new Date(this.item.createdon);
      return d.toLocaleDateString("ru-RU") + " " + d.toLocaleTimeString("ru-RU");
    },
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss">
@mixin table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover) {
  .comments-body {
    .comment-row {
      border-bottom: 1px solid $borderColor;
      background-color: $backgroundColor;
      &:hover {
        background: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
    .comment-row:nth-child(odd) {
      background-color: $backgroundColorSecond;
      &:hover {
        background-color: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
  }
}
.theme--dark {
  $color: #bdbdbd;
  $borderColor: #808080;
  $backgroundColor: #161616;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: lighten($backgroundColor, 3%);
  $backgroundColorHover: lighten($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
.theme--light {
  $color: #1a1a1a;
  $borderColor: #a3a3a3;
  $backgroundColor: #ffffff;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: darken($backgroundColor, 5%);
  $backgroundColorHover: darken($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
</style>

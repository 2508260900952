<template>
  <v-btn color="primary" @click="$emit('click')" class="px-0" icon>
    <v-icon class="mx-0">fas fa-print</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

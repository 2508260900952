<template>
  <v-row>
    <v-card class="ma-2">
      <a
        :href="$root.config.imageUrl + el"
        target="_blank"
        @click.prevent="
          fullImage = true;
          fullScr = el;
        "
        ><v-img
          :src="$root.config.imageUrl + el + '?width=300&height=300'"
          height="200px"
        />
      </a>
    </v-card>
    <a-view-image-dialog v-model="fullImage" :src="fullScr" />
  </v-row>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
  computed: {
    el: {
      get() {
        if (this.value) {
          try {
            return JSON.parse(this.value)[0];
          } catch (error) {}
        }
        return [];
      },
    },
  },
  data() {
    return {
      fullScr: "",
      fullImage: false,
    };
  },
};
</script>
<template>
  <div class="py-0">
    <a-form-model
      v-model="data"
      :model="model"
      :errors="errors"
      :config="{ dense: true, disabled: data.is_investor !== 1, readonly, hideDetails: 'auto' }"
      @validate="validate_($event)"
    />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, libChecks } from "@/components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, libChecks, getAccess],
  props: {
    value: [Object, Array],
    model: [Array],
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      panel: null,
      uploadsFiles: null,
      dialogVideoFile: false,
      dialogFile: false,
      fileLoading: false,
      showError: false,
      err: null,
      over: {},
      startLoc: 0,
      progressLoading: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },

    items: {
      get() {
        let d = [];
        try {
          // d = JSON.parse(this.value);
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
        //    this.$emit("input", this.isReverse ? v.reverse() : v);
      },
    },
  },
  created() {
    this.updateData(this.id);
  },
  methods: {
    async validate_(e) {
      await this.validate(e);
      this.$emit("input", this.data);
    },
    async fetchData(id) {
      this.loading = true;
      let response;
      try {
        let data = this.items;

        for (const name in data) {
          if (name == "data" && this.data?.data && data[name]) {
            for (const nameData in data.data) {
              this.$set(this.data.data, nameData, data.data[nameData]);
              this.$set(this.errors, nameData, null);
            }
          } else {
            this.$set(this.data, name, data[name]);
            this.$set(this.errors, name, null);
          }
        }
      } catch (error) {}
      this.loading = false;

      this.afterFetchData(response);
    },
  },
};
</script>
<style lang="scss">
.over {
  opacity: 0.6;
}
</style>

/** @format */

export default {
  data() {
    return { keys: {}, keysModified: 0 };
  },
  created() {
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (this.keys.ctrl !== e.ctrlKey) {
        this.keysModified++;
        //console.log(e.ctrlKey);
        this.keys.ctrl = e.ctrlKey;
        // this.$store.dispatch('state/keys', this.keys);
      }
    },
  },
};

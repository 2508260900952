<template>
  <v-row
    class="fill-height ma-0"
    align="center"
    justify="center"
    style1="height: 300px; overflow: auto"
    style="overflow: auto"
  >
    <v-card v-for="(el, key) in items" :key="key" width="150px" class="ma-2">
      <a
        :href="$root.config.imageUrl + el"
        target="_blank"
        @click.prevent="
          fullScr = el;
          fullImage = true;
        "
        ><v-img
          :src="
            $root.config.imageUrl + el + '?width=' + size + '&height=' + size
          "
          :height="size + 'px'"
        />
      </a>
    </v-card>
    <a-view-image-dialog v-model="fullImage" :src="fullScr" />
  </v-row>
</template>

<script>
export default {
  props: {
    value: Array,
    size: { type: Number, default: 150 },
  },
  computed: {
    items: {
      get() {
        if (this.value) {
          try {
            return this.value; //JSON.parse(this.value);
          } catch (error) {}
        }
        return [];
      },
    },
  },
  data() {
    return {
      fullImage: false,
      fullScr: "",
    };
  },
};
</script>
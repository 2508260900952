/** @format */

export default {
  methods: {
    getAccess(name, config = null, def = false) {
      const o = name.split('.');
      let obj = name;
      let conf = config || {};
      let access = {};
      let tmp;
      for (let i = 0; i < o.length; i++) {
        const el = o[i];
        if (i === 0) {
          tmp = JSON.parse(JSON.stringify(this.$store.getters['config/get'].config.access || {}));
        }
        tmp = tmp?.[el];
      }
      access = tmp;
      let res = def;
      if (!access) {
        access = {};
        res = def;
      } else {
        res = false;
      }
      const role = this.$root.profile.role;
      const user = this.$root.profile.id;
      if (access?.role?.length) {
        res = access.role.includes(role) || false;
        if (!res) {
          res = access.role.includes(-1) && !access.role.includes(-role) && !(access?.user && access?.user.includes(-user));
        }
      }
      if (!res && access?.user?.length) res = res || access?.user.includes(user) || false;
      if (!res && access?.inRole?.length && conf?.access) {
        conf.access.forEach((el) => {
          res = res || (user == el.user_id && access.inRole.includes(el.user_role)) || false;
        });
      }
      if (!res && conf?.access && !access?.inRole?.length && conf?.inRole == -1) {
        //отключил сделал inRole=-1
        if (
          conf.access.find((el) => {
            return el.user_id == user;
          })
        ) {
          res = true;
          //console.log('conf?.access !inrole', conf, res);
        }
      }
      if (!res && conf?.users) {
        // console.log('conf?.users', conf, res);
        res = conf.users.includes(user) || false;
      }
      if (!res && conf?.data) {
        console.log('daaaata');
        if (conf.data?.createdby_id == user) {
          res = true;
          //console.log('conf?.data', conf, res);
        }
      }

      //console.log('getAccess ' + name, { role, res }, access);
      return res;
    },
  },
};

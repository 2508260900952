<template>
  <v-menu offset-y v-if="docHistory.length > 1">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" icon small class="mx-1">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in docHistory" :key="index" link @click="$emit('click', item)">
        <v-list-item-title @click="$emit('clickDoc', item.id)">
          <small v-if="id"> (#{{ item.id }}) {{ getName(item) }}</small>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  props: {
    title: { type: String, default: "История" },
    id: Number,
    icon: { type: String, default: "fas fa-history" },
    m: { type: Object, default: () => {} },
  },
  data() {
    return {
      history: null,
    };
  },
  computed: {
    docHistory() {
      if (!this.history) {
        this.getHistory();
      }
      return this.history || [];
    },
  },
  methods: {
    getName(i) {
      const d = new Date(i.updatedon);

      let res = d.toLocaleDateString("ru-RU") + " " + d.toLocaleTimeString("ru-RU") + ` (${i?.user_name || ""})`;
      return res;
    },
    async getHistory() {
      let api = this.m?.api;
      if (!api || !this.id) return;
      let params = { filters: { origin_id: this.id } };
      //const d = await this.$axios.get(api + "/short_info", { params });
      const d = await this.$axios.post("/report_sql", { name: "getDocHistory", params: { id: this.id } });
      if (d.data && d.data.data) {
        this.history = d.data.data;
      }
    },
  },
};
</script>

<template>
  <div>
    <v-btn @click="showSelectDialog = true"> {{ name || "Выбор" }} </v-btn>
    <select-dialog v-model="showSelectDialog" v-if="showSelectDialog" :source="source" @select="$emit('select', $event)" :parent_data="parent_data" />
  </div>
</template>

<script>
export default {
  components: {
    selectDialog: () => import("./../dialogs/selectList"),
  },
  mixins: [],
  props: {
    name: String,
    title: String,
    parent_data: Object,
    source: { type: Object, default: () => {} },
  },
  data() {
    return {
      showSelectDialog: false,
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {},
};
</script>

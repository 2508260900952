<template>
  <div class="py-1 pb-3">
    <v-card>
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <div class="text-right pa-1">
          <v-btn v-if="!readonly" class="ma-1" @click="add()" color="primary"> Добавить </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-simple-table style="width: 100%">
              <tbody>
                <tr v-for="(el, key) in items" :key="key" v-if="!el.deleted">
                  <td class="text-left">
                    <a :href="$root.config.fileUrl + el.file" target="_blank">
                      {{ el.name }}
                    </a>
                  </td>
                  <td width="10px">
                    <v-btn v-if="!readonly" class="mx-2" fab dark small color="error" title="Удалить" @click.prevent="remove(key)">
                      <v-icon dark> mdi-close </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogFile" hide-overlay persistent max-width="600">
      <v-card>
        <v-card-title>Загрузка файлов</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input multiple outlined chips label="Выберите файл" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">Загрузить </v-btn>
          <v-btn @click="dialogFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: String | Array,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      uploadsFiles: null,
      dialogFile: false,
      fileLoading: false,
    };
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    items: {
      get() {
        let d;
        try {
          d = this.value;
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    remove(key) {
      let e = this.items;
      let d = e[key];
      d.deleted = { user_id: this.$root.profile.id, date: new Date() };
      e.splice(key, 1, d);
      this.items = e;
    },
    add() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.dialogFile = false;
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        // file.uploadStatus = 0;
        this.$axios
          .post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: (progressEvent) => {
            //   file.uploadStatus = parseInt(
            //     (progressEvent.loaded / progressEvent.total) * 100
            //   );
            // },
          })
          .then((response) => {
            let data = response.data.data.file;
            let el = this.items;
            if (!Array.isArray(el)) el = [];
            el.push({
              file: data,
              name: file.name,
              uploaded: { user_id: this.$root.profile.id, date: new Date() },
            });
            this.items = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            // this.$root.$emit("show-info", {
            //   text: "Error: " + error,
            // });
            reject(error);
          });
      });
    },
  },
};
</script>

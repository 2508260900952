<template>
  <v-dialog :value="value" @input="$emit('input')" persistent>
    <v-card
      style="
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        width: auto;
      "
    >
      <v-card-title
        v-if="$slots.title"
        style="
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          border-bottom: 1px solid #ddd;
        "
      >
        <slot name="title"></slot>
        <v-spacer></v-spacer>
        <v-btn
          fab
          dark
          small
          color="blue-grey"
          title="Закрыть окно"
          @click="$emit('input')"
          ><v-icon dark> mdi-close </v-icon></v-btn
        >
      </v-card-title>
      <v-card-text
        class="py-10"
        style="
          position: absolute;
          top: 67px;
          bottom: 52px;
          left: 0;
          right: 0;
          overflow: auto;
        "
      >
        <div class="text-center">
          <slot />
        </div>
      </v-card-text>

      <v-card-actions
        style="
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-top: 1px solid #ddd;
          overflow: auto;
        "
      >
        <v-divider></v-divider>
        <slot name="actions">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('input')">Закрыть</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    width: {
      type: Number,
      default: 600,
    },
  },
};
</script>
<template>
  <span>{{ v }}</span>
</template>

<script>
export default {
  props: {
    value: [String],
    model: Object,
  },
  computed: {
    v() {
      if (!this.value) return "none";

      const d = new Date(this.value);
      return d.toLocaleDateString("ru-RU");
    },
  },
};
</script>
export default {
    ru: {
        Registration: "Регистрация",
        Login: "Войти",
        close: "Закрыть",
        cancel: "Отменить",
        clear: "Очистить",
        ok: "Ок",
        All: "Все",
        'login error': "Ошибка входа"
    }
}
<template>
  <div :style="style">
    <v-chip v-show="item.show !== false" v-for="item in list" :key="item.value" small color="primary" class="pa-1" style="margin: 1px 1px"> {{ item.text }}</v-chip>
  </div>
</template>

<script>
import { modelOptions } from "./../../../mixings";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: [Object],
    model: {
      type: Object,
      default: {},
    },
  },
  computed: {
    list() {
      let list = JSON.parse(JSON.stringify(this.getOptions(this.model) || []));

      return list.filter((l) => {
        return this.value && this.value.toString(2).bin2array().includes(l.value);
      });
    },
    background() {
      let text = this.getElementByValue(this.value, this.model);
      return text?.bgColor ? "background-color:" + text.bgColor : "";
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
};
</script>

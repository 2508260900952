<template>
  <dialog-edit :value="value" @input="$emit('input')" :maxWidth="'100hv'">
    <template v-slot:title>
      {{ title }}
    </template>
    <v-card-text style="height: 80vh; overflow: auto" class="pa-0">
      <a-table-f-api ref="table" :api="api" :model="modelList" :useQuery="fasle" :limit="0" :defaults="defaults" @click="onClickRow($event)" />
    </v-card-text>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <a-btn-save @click="add()" :title="'Выбрать'" />
      <v-btn @click="$emit('input')">Закрыть</v-btn>
    </template>
  </dialog-edit>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess],
  props: {
    value: Boolean,
    title: String,
    api: String,
    model: Array,
    multiple: { type: Boolean, default: false },
    defaults: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    modelList() {
      let res = [...this.model];
      if (this.multiple) res = [{ name: "checked", type: "checkbox", width: 35 }, ...res];
      return res;
    },
  },
  created() {
    this.init();
  },
  watch: {
    value() {
      if (this.value) {
      }
    },
    api() {
      this.init();
    },
  },
  methods: {
    init() {},

    clickRow(d) {
      this.$refs.table.$refs.base.clickCheckbox(d.event, d.row);
      this.$refs.table.$refs.base.$forceUpdate();
    },
    add() {
      let rows = this.$refs.table.data.filter(el => {
        return el?.checked;
      });
      this.$emit("save", rows);
      this.$emit("input");
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',{attrs:{"outlined":"","value":_vm.text,"label":_vm.model.title,"error-messages":_vm.error,"disabled":_vm.disabled,"hide-details":_vm.config.hideDetails || false,"readonly":"","persistent-placeholder":"","placeholder":"Не указано","clearable":!!_vm.value && !_vm.readonly,"dense":_vm.config.dense || false,"append-icon":!_vm.value ? 'mdi-calendar' : ''},on:{"click":function($event){_vm.showPicker = true},"click:append":function($event){_vm.showPicker = true},"click:clear":function($event){_vm.$emit('input', null);
          _vm.initValue = null;}}})]}}]),model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[(_vm.showPicker)?_c('v-date-picker',{attrs:{"disabled":_vm.readonly,"full-width":""},on:{"dblclick:date":function($event){_vm.$emit('input', _vm.d);
        _vm.initValue = _vm.d;
        _vm.$refs.dialog.save();}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.$emit('input', _vm.initValue);
          _vm.showPicker = false;}}},[_vm._v("Закрыть")]),(!_vm.readonly)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$emit('input', _vm.d);
          _vm.initValue = _vm.d;
          _vm.$refs.dialog.save();}}},[_vm._v("Записать")]):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="f-table-col" :class="[sortable ? 'sortable' : '']" :style="{ width: width + 'px', 'background-color': color }" @click.self="$emit('sort', field)">
    <slot></slot>
    <template v-if="sortable">
      <span v-if="sortCurrent.key != field">
        <i class="fas fa-sort"></i>
      </span>
      <span v-else class="arrow">
        <i class="fas" :class="sortCurrent.order == 'DESC' ? 'fa-sort-down' : 'fa-sort-up'"></i>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    sort: {
      type: [Object, Array],
      default: () => {},
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 100,
    },
    field: {
      type: String,
      default: "",
    },
  },
  computed: {
    sortCurrent() {
      if (Array.isArray(this.sort)) return this.sort.find(el => !el?.default) || {};
      else return this.sort;
    },
  },
};
</script>

<template>
  <div>
    <qrcode v-if="value" :value="value" :options="params"></qrcode>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default: () => {
        return { width: 300 };
      },
    },
    value: { type: String, default: "" }, // Строка для генерации QR-кода
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
/* Стили могут быть добавлены по вашему усмотрению */
</style>

<template>
  <v-row
    class="no-gutters flex-nowrap flex-row comment-row"
    :class="{
      notviewed: !item.viewedon && item.createdby_id !== $root.profile.id,
    }"
    @click.left="$emit('click', $event)"
  >
    <v-col cols="auto" class="shrink pa-2">
      <div class="flex">
        <h4 class="flex">{{ item.user_name }}</h4>
        <h6 class="flex">{{ messageDate }}</h6>
      </div>
    </v-col>
    <v-col
      cols="auto"
      class="grow pa-2"
      :class="{
        'text-right': item.user_id == $root.profile.id,
        'text-left': item.user_id !== $root.profile.id,
      }"
      style="flex-basis: 0"
    >
      {{ item.content }}
    </v-col>
  </v-row>
</template>

<script>
import { components } from "@/components/mixings";

export default {
  mixins: [components],
  components: {},
  props: {
    id: Number,
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  destoyed() {},
  created() {},
  mounted() {},

  computed: {
    messageDate() {
      const d = new Date(this.item.createdon);
      return d.toLocaleDateString("ru-RU") + " " + d.toLocaleTimeString("ru-RU");
    },
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss">
@mixin table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover) {
  .comments-body {
    .comment-row {
      border-bottom: 1px solid $borderColor;
      background-color: $backgroundColor;
      &:hover {
        background: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
    .comment-row:nth-child(odd) {
      background-color: $backgroundColorSecond;
      &:hover {
        background-color: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
  }
}
.theme--dark {
  $color: #bdbdbd;
  $borderColor: #808080;
  $backgroundColor: #161616;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: lighten($backgroundColor, 3%);
  $backgroundColorHover: lighten($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
.theme--light {
  $color: #1a1a1a;
  $borderColor: #a3a3a3;
  $backgroundColor: #ffffff;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: darken($backgroundColor, 5%);
  $backgroundColorHover: darken($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
</style>

<template>
  <v-lazy v-model="lazy" :options="{ threshold: 0.5 }">
    <span v-if="lazy">
      <v-img v-if="count == 1" :src="image ? $root.config.imageUrl + getImage(image) + `?width=${size}&height=${size}` : ''"> </v-img>
      <img v-else-if="image" v-for="(img, key) in image" :key="key" :src="$root.config.imageUrl + getImage(img) + `?width=${size}&height=${size}`" class="pl-1 pt-1" />
      <v-chip class="ma-0 px-2 ml-1" style="position: absolute; top: 4px" v-if="totalCount > count && count > 1"> +{{ totalCount - count }} </v-chip>
    </span>
  </v-lazy>
</template>

<script>
export default {
  props: {
    value: [String, Array],
    values: Object,
    model: Object,
  },
  data() {
    return {
      count: this.model?.count || 1,
      size: this.model?.sizeImg || 32,
      totalCount: 0,
      loaded: false,
      lazy: false,
      loading: true,
    };
  },
  computed: {
    image() {
      let photos = this.value;
      if (!photos && !this.value && this.model?.target) photos = this.values[this.model.target]?.[this.model.name];
      if (Array.isArray(photos)) {
        this.totalCount = photos.length;
        return photos.slice(0, this.count);
      }
      return photos;
    },
  },
  methods: {
    getImage(name) {
      let res = name;
      if (res.includes("video#")) {
        res = res.split("#")[1];
      }
      return res;
    },
  },
};
</script>

<template>
  <a-table-f-base
    ref="base"
    :model="model"
    :data="data"
    :loading="loading"
    :sort="sort"
    :filters="filters"
    :searchable="searchable"
    :editItem="editItem"
    :footerHide="footerHide"
    :paramName="defaults.paramName || ''"
    @click="onClickRow($event)"
    @dblclick="onDblClickRow($event)"
    @sort="onSort($event)"
    @change-filter="onInputFilter($event)"
    @scrollEnd="onScrollEnd($event)"
    @checkedAll="setCheckAll($event)"
  >
    <template v-slot:top>
      <slot name="top" />
    </template>
    <template v-for="el in model" v-slot:[getSlotName(el)]="{ item, row, field }">
      <slot :name="getSlotName(el)" :item="item" :row="row" :field="field" />
    </template>
    <template no-data>
      <slot name="no-data" />
    </template>
    <template v-slot:footer>
      <slot name="footer">
        <div class="f-table-footer" v-if="footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-for="(el, key) in footer" :key="key">{{ key }}: {{ el }}</div>
          </div>
        </div>
      </slot>
    </template>
  </a-table-f-base>
</template>

<script>
import { getIndex } from "../../../mixings";
import lodash from "lodash";

export default {
  mixins: [getIndex],
  props: {
    dataTable: Array,
    model: [Object, Array],
    useQuery: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    defaults: {
      type: Object,
      default: () => {
        return {};
      },
    },
    footer: {
      type: Object,
      default: () => {},
    },
    footerHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlPrefix: "q",
      editItem: {},
      data: null,
    };
  },
  computed: {
    getData() {
      console.log("getData", this.data);
      return this.data;
    },
  },
  watch: {
    $route() {
      this.updateData();
    },
    dataTable() {
      this.updateData();
    },
  },
  created() {
    this.init();
  },
  methods: {
    getSlotName(model) {
      return `item.` + model.name;
    },
    setCheckAll(v) {
      for (const el of this.data) {
        if (el?.check_disable !== 1) el.checked = v;
      }
    },
    onClickRow(event) {
      if (event.field.type === "folder" && event.row.isparent) {
        this.filters.parent_id = event.row.id;
        this.onChangeFilter();
      }
      if (event.field?.typeEdit) {
        this.editItem = {
          row: event.row,
          field: event.field.name,
          key: event.key,
        };
      }
      this.$emit("click", event);
    },
    init() {
      this.date = [];
      if (this.defaults.filters) this.filters = this.defaults.filters;
      else this.filters = {};
      if (this.defaults.sort) this.sort = this.defaults.sort;
      else this.sort = { key: "id", order: "ASC" };
      this.reloadData();
    },
    reloadData() {
      this.updateData();
    },
    updateData() {
      this.$emit("update-data", this);
      this.fetchData();
    },
    async fetchData(append = false) {
      this.loading = true;
      let data = JSON.parse(JSON.stringify(this.dataTable || []));
      if (this.filters && data) {
        for (const key in this.filters) {
          let val = this.filters[key].value;
          let type = this.filters[key].condition;
          if (type == "LIKE") val = val.replaceAll("%", "");
          data = data.filter((d) => {
            if (type == "LIKE") return d[key].includes(val);
            return true;
          });
        }
      }
      if (this.sort?.key && data && (data[0] || {}).hasOwnProperty(this.sort.key)) {
        let key = this.sort?.key;
        this.data = data.sort((a, b) => {
          if (this.sort.order == "DESC") return (a[key] > b[key]) - (a[key] < b[key]);
          return (a[key] < b[key]) - (a[key] > b[key]);
        });
      } else this.data = data;
      this.loading = false;
    },

    onSort: function (el) {
      if (this.sort.key == el) {
        if (this.sort.order == "ASC") {
          this.sort.order = "DESC";
        } else {
          this.sort.order = "ASC";
        }
      } else {
        this.sort.key = el;
        this.sort.order = "ASC";
      }
      if (this.useQuery) {
        this.updateUrlParams();
      } else {
        this.updateData();
      }
    },
    updateUrlParams: function () {
      var params = {
        sort: this.sort,
        filters: this.filters,
      };
      this.$router.push({
        query: { [this.urlPrefix]: JSON.stringify(params) },
      });
    },
    onInputFilter: lodash.debounce(function () {
      this.onChangeFilter();
    }, 500),
    onChangeFilter: function () {
      if (this.useQuery) {
        this.updateUrlParams();
      } else {
        this.updateData();
      }
      this.$emit("change-filter", this.filters);
    },
    onScrollEnd(e) {},
  },
};
</script>

<template>
  <v-btn color="primary" @click="$emit('click')" :disabled="disabled" class="px-2" :title="title">
    <v-icon v-if="icon">{{ icon }}</v-icon>
    <v-icon v-else>fas fa-plus</v-icon>
    <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title">{{ title }}</btn-title>
  </v-btn>
</template>
 
<script>
export default {
  props: {
    size: { type: String, default: "sm" },
    title: { type: String, default: "Добавить" },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: "" },
  },
};
</script>

<template>
  <v-autocomplete
    class="f-search"
    solo
    append-icon=""
    :value="searchValue"
    @input="setFilter"
    @change="setFilter"
    :items="[{ text: $t('All'), value: '***' }, ...getSortOptions]"
    hide-details="true"
    dense
    item-text="text"
    item-value="value"
    :clearable1="searchValue != '***'"
    @click:clear.prevent="clearValue()"
    @click:append="append()"
  >
    <template v-slot:item="{ item }">
      <span class="f-search-option">{{ item.text }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
import modelOptions from "@/components/libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array, Object],
    model: Object,
  },
  data() {
    return {
      field: null,
    };
  },
  created() {},
  methods: {
    clearValue() {
      this.searchValue = "***";
      return true;
    },
    append() {
      this.searchValue = "***";
      return true;
    },
    setFilter(f) {
      //console.log("setFilter", typeof f, f);
      this.searchValue = f;
      //this.$emit("change", `${f}`);
    },
  },
  computed: {
    searchValue: {
      get() {
        let res = this.value != parseInt(this.value) ? this.value : parseInt(this.value);
        if (res === undefined || res == "null" || res === "") res = "***";
        console.log("get", res);
        return res;
      },
      set(v) {
        if (v == "***" || v === null) v = "";
        //if (typeof v === "number") v = v.toString();
        console.log("set ", v);
        this.$emit("change", v);
      },
    },
    getSortOptions() {
      let list = JSON.parse(JSON.stringify(this.getFilterOptions(this.model) || []));
      let res = [];
      list.forEach((l) => {
        let r = {};
        if (typeof l === "string") r = { text: l, value: l };
        else if (!l.text) r = { text: l.value, value: l.value };
        else r = l;
        if (l.show !== false) res.push(r);
      });
      res = res.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
      return res;
    },
  },
};
</script>
<style lang="scss">
@import "styles.scss";
.f-search-option {
  font-size: 12px;
}
.f-search .v-list-item,
.f-search .v-list-item::after {
  padding: 2px;
  min-height: 24px !important;
}
.f-search.v-text-field--solo {
  color: #444;
  border-radius: 3px;
  height: 20px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 12px;
}
.f-search .v-label {
  font-size: 10px;
}
.f-search .v-select__slot,
.f-search .v-select__selections {
  height: 20px;
  margin: 0;
  padding: 0 !important;
}
.f-search .v-input__slot {
  padding: 0px 5px !important;
  min-height: 10px !important;
}
.f-search input {
  padding: 0 !important;
}
</style>

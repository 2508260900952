/** @format */

export default {
  methods: {
    async getCount(api, filters) {
      const resp = await this.$axios.get(api + '/count', { params: { filters } });
      if (!(resp.data.status == 'ok')) {
        return -1;
      }
      return resp.data.data;
    },

    async getCount2(api, filters) {
      const resp = await this.$axios.get(api, { params: { filters } });
      if (!(resp.data.status == 'ok')) {
        return -1;
      }
      return resp.data.data.length;
    },
  },
};

<template>
  <v-combobox
    outlined
    :clearable="!readonly"
    :items="data"
    :value="value"
    menu-props="offset-y"
    type="number"
    :search-input.sync="val"
    :return-object="false"
    :label="$t(titleValue)"
    :error-messages="error"
    @input="$emit('input', $event)"
    :hide-details="config.hideDetails || false"
    :readonly="readonly"
    :dense="config.dense || false"
    @click:clear="handleChipClick(null)"
  >
    <template v-slot:item="{ item }">
      <template v-if="!item[fieldTitle]" />
      <template v-else>
        <v-list-item v-if="fieldValues">
          <v-subheader>{{ item[fieldTitle] }}</v-subheader>
          <v-chip v-for="(v, i) in fieldValues" :key="i" small class="ma-1" @click="handleChipClick(item[v])" :color="value == item[v] ? 'green' : ''">
            {{ item[v] }}
          </v-chip>
        </v-list-item>
      </template>
    </template>
  </v-combobox>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [Number, String],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },

    model: Object,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {}, disabled: false },
  },
  data() {
    return {
      data: [],
      cc: 0,
      title: null,
      preText: null,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    fieldValues() {
      let res = this.model?.fields?.values || "";
      res = res.split(",");
      return res;
    },
    fieldTitle() {
      let res = this.model?.fields?.title || "id";
      return res;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    titleValue() {
      return this.title || this.model.title;
    },
  },
  methods: {
    handleChipClick(v) {
      this.$emit("input", v);
    },
    fff(v) {
      console.log("dddddd", v);
    },
    async getData() {
      if (this.model.api) {
        await this.fetchData();
      } else this.data = this.getSortOptions(this.model);
      let res = [...this.data];
      res = this.filterItems(res);
      res = res.filter((f) => {
        return !f.disabled;
      });
      this.data = res;
    },
    async fetchData() {
      let api = this.model.api;
      if (!api) return;
      let params = {};
      if (this.model.sort) {
        params.sort = this.model.sort;
      }
      if (this.model.filters) {
        if (this.model.filters.formula) {
          let res = eval(this.model.filters.formula || "{}");
          if (typeof res === "function") res = res(this.values);
          params.filters = res;
        } else params.filters = this.model.filters;
      }
      if (this.model?.where) {
        params.where = this.model.where;
      }
      this.loading = true;
      let response = await this.$axios.get(api, { params });
      this.loading = false;
      this.data = this.filterItems(response.data.data).multiSort(["text"]);
    },
    getSortOptions(model) {
      let list = JSON.parse(JSON.stringify(this.getOptions(model) || []));

      return list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
  },
};
</script>

<template>
  <span :style="style">
    <span v-if="preText && value">
      {{ preText }}
    </span>
    {{ text }}
  </span>
</template>

<script>
import { modelOptions } from "@/components/mixings";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: [Object],
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: [],
      text: null,
      preText: null,
      documents: {
        AccountingDocGoodsMovingModel: { preText: "Движение №", api: "accounting/doc/goods_moving" },
        AccountingDocGoodsIncomeModel: { preText: "Приемка №", api: "accounting/doc/goods_income" },
        AccountingDocOrderServiceModel: { preText: "Заказ услуг №", api: "accounting/doc/order_service" },
        AccountingDocOrderGoodModel: { preText: "Заказ товаров №", api: "accounting/doc/order_good" },
        AccountingDocOrderGoodsModel: { preText: "Заказ товаров №", api: "accounting/doc/order_good" },
        AccountingDocOrderWithdrawalModel: { preText: "Расходный ордер №", api: "accounting/doc/order_withdrawal" },
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
  watch: {
    value(v) {
      if (v) this.fetchData();
      else this.text = "";
    },
  },
  methods: {
    getApi(model) {
      let api = "";
      if (model?.apiVar) {
        const key = model.apiVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const name = model.apiVar.apiName.find((el) => {
            return el.value == v;
          });
          if (name?.name) {
            api = name.name;
            this.preText = name?.preText;
          }
        }
        return api;
      }
      return api;
    },
    async fetchData() {
      if (!this.value) {
        this.text = "";
        return;
      }
      const model = this.model;
      let doc_name = model.doc_name?.value ? model.doc_name?.value : this.values?.[model.doc_name.key];
      let doc_id = model.doc_id?.value ? model.doc_id?.value : this.values?.[model.doc_id.key];

      if (!doc_name || !doc_id) {
        this.text = "error";
        console.log("can not identify document", doc_name, doc_id);
        return;
      }
      let api = this.documents?.[doc_name]?.api;
      if (!api) {
        this.text = doc_name + "#" + doc_id;
        return;
      }
      let filters = { id: doc_id };
      this.loading = true;
      let response = await this.$axios.get("/" + api, { params: { filters } });
      this.loading = false;
      this.data = response.data.data;
      this.text = this.documents?.[doc_name].preText + (this.data[0]?.code_doc || `#${doc_id}` || "??");
    },
  },
};
</script>

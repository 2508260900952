var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dialog-edit2',{attrs:{"value":_vm.value,"makeFocus":false},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.source.title || _vm.title)+" ")]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.$emit('input')}}},[_vm._v("Закрыть")])]},proxy:true}])},[_c('v-card-text',{staticClass:"pa-0",staticStyle:{"height":"70vh","overflow":"auto"}},[(_vm.type == 'api')?_c('a-table-f-api',{ref:"table",attrs:{"api":_vm.url,"model":_vm.model,"useQuery":false,"defaults":{
          sort: { key: 'id', order: 'DESC' },
          filters: _vm.filter,
          paramName: _vm.tableParamName,
        }},on:{"click":_vm.clickRow}}):_vm._e(),(_vm.type == 'data')?_c('a-table-f-data2',{ref:"table",attrs:{"dataTable":_vm.data,"model":_vm.model,"searchable":false,"useQuery":false,"defaults":{
          sort: { key: 'id', order: 'DESC' },
          filters: _vm.filter,
          paramName: _vm.tableParamName,
        }},on:{"click":_vm.clickRow}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-html="value"></div>
</template>

<script>
export default {
  props: {
    value: [String, Number, Boolean, Object, Array],
    model: {
      type: Object,
      default: {},
    },
  },
};
</script>
<template>
  <v-menu offset-y v-if="items.length">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" v-bind="attrs" v-on="on" :disabled="disabled" depressed min-width="38" class="mr-1 px-0">
        <v-icon>{{ icon }}</v-icon>
        <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title">{{ title }}</btn-title>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index" @click="$emit('click', item)">
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    size: { type: String, default: "sm" },
    color: String,
    title: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    items: {
      type: Array,
      default: () => [],
    },
    icon: { type: String, default: "mdi-debug-step-over" },
  },
};
</script>

<template>
  <v-lazy v-model="lazy" :options="{ threshold: 0.5 }" :key="values.id">
    <div v-if="lazy">
      <v-progress-circular v-if="loading" indeterminate color="green" />
      <span v-else> {{ saldo }} </span>
    </div>
  </v-lazy>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: [],
      text: null,
      credit: null,
      debit: null,
      loaded: false,
      lazy: false,
      loading: true,
    };
  },
  created() {},
  watch: {
    "values.id"() {
      this.loaded = false;
    },
    lazy(v) {
      if (v) this.getBalance();
    },
  },
  computed: {
    saldo() {
      if (!this.loaded) this.getBalance();
      return (this.credit - this.debit).toLocaleString();
      if (this.credit - this.debit == 0) return this.credit - this.debit;
      if (this.credit && !this.debit) return this.credit - this.debit;

      return `${this.credit - this.debit} (${this.credit})`;
      return this.credit - this.debit;
    },
  },
  methods: {
    async fetchData() {
      return;
    },
    async getBalance() {
      this.loaded = true;
      this.loading = true;
      let id = this.values.id;
      let debit = 0;
      let credit = 0;
      let acc = this.model?.account || "";
      let resp = await this.$axios.post("accounting/records/report-sql", {
        q: `
select sum(value_cr) 'value_cr', sum(value_db) 'value_db' from (        
select id, credit_account 'account', value 'value_cr', null 'value_db'
from accounting_record
where credit_account like "${acc}" and credit_subconto_value_2=${id}
union all
select id, debit_account 'account',	null 'value_cr', value 'value_db'
from accounting_record
where debit_account like "${acc}" and debit_subconto_value_2=${id}
)  tt`,
      });
      this.loading = false;
      if (resp.data.status == "ok") {
        resp.data.data.forEach((el) => {
          debit += el.value_db;
          credit += el.value_cr;
        });
      }

      this.credit = credit;
      this.debit = debit;
    },
  },
};
</script>

<template>
  <v-layout px-2 py-2 mt-1 row wrap>
    <v-flex xs12 sm4 px-2 sm-pb-0>
      <a-input-select :model="modelOperations" :values="data" v-model="operation" :config="config" :error="getError(modelOperations)" />
    </v-flex>
    <v-flex xs12 sm4 px-2 sm-pb-0>
      <v-select
        outlined
        clearable
        :items="modelTypeOperations.options"
        item-text="text"
        return-object
        v-model="typeOperation"
        :label="$t(modelTypeOperations.title)"
        :error-messages="getError(modelTypeOperations)"
        :disabled="!operation"
        :hide-details="config.hideDetails || false"
        :dense="config.dense || false"
      />
    </v-flex>
    <v-flex xs12 sm4 px-2 sm-pb-0>
      <v-select
        v-if="subconto"
        outlined
        clearable
        :items="subcontoList"
        item-text="text"
        item-value="value"
        v-model="typeSubconto"
        :label="$t(modelTypeSubconto.title)"
        :error-messages="getError(modelTypeSubconto)"
        :disabled="!(modelTypeSubconto && modelTypeSubconto.options && typeOperation)"
        :hide-details="config.hideDetails || false"
        :dense="config.dense || false"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },

    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,

    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: false };
      },
    },
  },
  data() {
    return {
      accounts: this.$store.getters["config/get"].models.finance.accounts,
      operation1: null,
      typeOperation1: null,
      typeSubconto1: null,
      subconto: null,
      subcontoList: null,
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.subconto = null;
      }
    },
    operation1(v) {
      this.typeOperation = null;
    },
    typeOperatio1n(v) {
      this.typeSubconto = null;
    },
  },
  computed: {
    operation: {
      get() {
        if (!this.modelOperations) return null;
        return this.modelOperations?.json ? this.data.data[this.modelOperations.name] : this.data[this.modelOperations.name];
      },
      set(v) {
        this.onInput(v, this.modelOperations);
        if (this.typeOperation)
          this.$nextTick(() => {
            this.typeOperation = null;
            this.onInput(null, this.modelTypeOperations);
          });
      },
    },
    typeOperation: {
      get() {
        if (!this.modelTypeOperations) return null;
        let res = this.modelTypeOperations?.json ? this.data.data[this.modelTypeOperations.name] : this.data[this.modelTypeOperations.name];

        if (res) {
          const subconto = this.modelTypeOperations.options.find((el) => {
            console.log(el.value, res);
            return el.value == res;
          })?.subconto;
          this.subconto = subconto || null;
        }
        return res;
      },
      set(v) {
        let value = v ? v.value : null;
        const subconto = this.subconto;
        //if (this.typeSubconto) this.onInput(null, this.modelTypeSubconto);
        this.onInput(value, this.modelTypeOperations);
        if (this.typeSubconto)
          this.$nextTick(() => {
            this.onInput(null, this.modelTypeSubconto);
          });
        this.subconto = v?.subconto;
      },
    },
    typeSubconto: {
      get() {
        if (!this.modelTypeSubconto) return null;
        let res = this.modelTypeSubconto?.json ? this.data.data[this.modelTypeSubconto.name] : this.data[this.modelTypeSubconto.name];
        return res;
      },
      set(v) {
        this.onInput(v, this.modelTypeSubconto);
      },
    },
    data: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    },
    model() {
      return JSON.parse(JSON.stringify(this.accounts.form));
    },
    modelOperations() {
      let model = this.model.find((el) => {
        return el.name == "type";
      });
      model.options = this.accounts.operations;
      return model;
    },
    modelTypeOperations() {
      let model = this.model.find((el) => {
        return el.name == "typeOperation";
      });
      let options = this.accounts.typeOperations.filter((el) => {
        return el.operation == this.operation;
      });
      model.options = options;

      return model;
    },
    modelTypeSubconto() {
      let model = this.model.find((el) => {
        return el.name == "typeSubconto";
      });
      this.subcontoList = [];
      if (this.subconto) {
        let list = [];
        if (this.subconto.type == "list") {
          list = this.subconto ? this.accounts[this.subconto.source] : [];
          for (const el of list) {
            if (!el?.text) el.text = el.value;
          }
          this.subcontoList = list;
        } else if (this.subconto.type == "api") {
          this.getApiData(this.subconto.source).then((res) => {
            list = res;
            this.subcontoList = list;
          });
        }
        model.options = list.sort();
        model.title = this.subconto?.title || "";
      }
      return model;
    },
  },
  methods: {
    async onInput(e, el) {
      console.log("onInput in selectAccount");
      if (!el) return;
      if (el?.json) {
        let field = el?.jsonField || "data";
        let data = JSON.parse(JSON.stringify(this.data[field]));
        //let data = this.data.data;
        // data.data[el.name] = e;
        data[el.name] = e;

        this.data[field] = data;
      } else if (typeof e === "object" && e !== null) {
        if (this.data[el.name] !== null) {
          Object.assign(this.data, e);
        } else {
          this.data[el.name] = e;
        }
        //Object.assign(this.data, e);
      } else {
        this.data[el.name] = e;
      }
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            this.$emit("validate", e);
          }
      }
      this.$emit("validate", el.name);
    },
    getError(el) {
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            if (this.errors?.[e])
              err.push(
                this.model.find((m) => {
                  return m.name == e;
                })?.title +
                  ": " +
                  this.errors?.[e]
              );
          }
        return err.join(" , ");
      }

      return this.errors[el.name];
    },
    onInputOperation(e) {
      this.operation = e;
      //this.$emit("input", { event: e, el: this.modelOperations });
    },
    onInputTypeOperation(e) {
      this.typeOperation = e;
      console.log("select type operation", e);
    },
    onInputTypeSubconto(e) {
      this.typeSubconto = e;
      console.log("select type Subconto", e, this.typeSubconto);
    },
    async getApiData(api) {
      this.loading = true;
      let response = await this.$axios.get(api);
      this.loading = false;
      return response.data.data;
    },
  },
};
</script>
